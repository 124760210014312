<template>
  <div class="mb-3" v-if="scores">
    <div class="tabs">
      <div 
        v-for="(tab, index) in tabs" 
        :key="index" 
        :class="['tab', { active: activeTab === tab.name, final: tab.name === 'final' }]" 
        @click="tab.name !== 'final' && (activeTab = tab.name)"
      >
        <span class="tab-label">{{ tab.label }}</span>
        <div class="d-flex align-items-end">
          <span v-if="tab.name === 'article'" class="tab-emoji">✨</span>
          <span v-else :class="['tab-score', getScoreClass(tab.score)]">{{ tab.score }}</span>
          <span v-if="tab.name === 'final'" class="final-score-text">/100</span>
        </div>
      </div>
    </div>
    <div class="tab-content">
      <div v-html="activeDescription"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scores: {
      type: Object,
      required: true
    },
    descriptions: {
      type: Object,
      required: true
    },
    video: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTab: 'hook', // устанавливаем начально активную вкладку
      tabs: this.initializeTabs()
    };
  },
  computed: {
    activeDescription() {
      return this.activeTab === 'article' ? this.video.article : this.descriptions[this.activeTab];
    }
  },
  methods: {
    initializeTabs() {
      const baseTabs = [
        { name: 'final', label: 'Оценка', score: this.scores.final },
        { name: 'hook', label: 'Захват', score: this.scores.hook },
        { name: 'flow', label: 'Поток', score: this.scores.flow },
        { name: 'engagement', label: 'Интерес', score: this.scores.engagement },
        { name: 'trend', label: 'Тренд', score: this.scores.trend },
      ];
      if (this.video.article) {
        baseTabs.push({ name: 'article', label: 'Статья' }); // Добавляем таб "Статья", если video.article не пустой
      }
      return baseTabs;
    },
    getScoreClass(score) {
      if (score.includes('A')) return 'score-a';
      if (score.includes('A-')) return 'score-a-minus';
      if (score.includes('B')) return 'score-b';
      if (score.includes('C')) return 'score-c';
      return '';
    }
  }
};
</script>

<style>
.tabs {
  display: flex;
  margin-left: 0;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  transition: border-bottom 0.3s;
}

.tab.final {
  pointer-events: none;
}

.tab.active {
  background: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.tab-label {
  display: block;
  font-weight: bold;
}

.tab-score, .tab-emoji {
  display: block;
  font-weight: 600;
  font-size: 1.4rem;  
}

.tab.final .tab-score {
  font-size: 2rem;
  font-weight: bold;
  line-height: 2rem;
  color: #00d300;
}

.final-score-text {
  font-size: 14px;
  margin-left: 2px;
  color: #999;
}

.score-a {
  color: #81f200;
}

.score-a-minus {
  color: #5fb200;
}

.score-b {
  color: #ff9711;
}

.score-c {
  color: #ff3333;
}

.tab-content {
  user-select: text;
  padding: 10px 20px;
  background: #000;
  border-radius: 20px;
}
</style>
