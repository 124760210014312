<template>
  <div class="header shadow-sm rounded py-3 ps-4 pe-2 mt-2">
    <a name="header"></a>
    <div class="d-flex justify-content-between align-items-center me-2">

        <a :href="configData.link" class="text-decoration-none" v-if="configData.logo_thumbs && (!channelItem.logo_thumbs || $route.name == 'Collections')">
        <div class="tg-link d-flex align-items-center" >
          
          <img :src="configData.logo_thumbs.middle" width="250" class="" alt="" />
        </div>
        </a>


        <a :href="channelItem.link" class="text-decoration-none" v-if="channelItem.logo_thumbs && $route.name !== 'Collections' ">
        <div class="tg-link d-flex align-items-center" >
          
          <img :src="channelItem.logo_thumbs.middle" class="ms-2 header-logo" width="250" alt="" />
          
        </div>
        </a>


      <div class="menu d-flex align-items-center" v-if="isMobile">
        <button @click="openMenu" class="btn btn-primary me-2"><i class="bi bi-list"></i></button>
      </div>

      <div v-else class="menu d-flex align-items-center">

        <div v-if="isAuthenticated && !articleItem.disable_topmenu_buttons && !channelItem.disable_topmenu_buttons" class="me-4">

          <router-link to="/channels" v-if="configData && !configData.hide_channels_menu_btn">
            <button class="btn btn btn-outline-primary">Мои каналы</button>
          </router-link>

          <router-link to="/collections" v-if="configData && !configData.hide_collections_menu_btn">
            <button class="btn btn btn-outline-primary">Курсы и плейлисты</button>
          </router-link>

          <router-link to="/materials">
            <button class="btn btn btn-outline-primary">Материалы</button>
          </router-link>
        </div>
          
        <telegram-auth v-if="route && route.name !== 'Main' && route.name !== 'Collections' && (!collectionItem.id || isAuthenticated) && !configData.disable_telegram_auth && !(route.name == 'Materials' && !isAuthenticated && configData.service_name == 'youtest' )"></telegram-auth>


<!--       <router-link to="/collections">
        <button class="btn btn-primary btn-gradient">Создать курс или плейлист</button>
      </router-link> -->


<!--           <a v-if="articleItem.hasTest" href="#test" class="text text-primary me-2">Тест к материалу</a>
          <a v-if="articleItem.hasTimecodes" href="#timecodes" class="text text-primary me-2">Таймкоды</a>
 -->
          
      </div>
      
    </div>

    <div class="modal" v-show="showMenu" tabindex="-1" role="dialog" :class="{ 'd-block': showMenu }">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Меню</h5>
            <button type="button" class="btn btn-light close" @click="closeMenu">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
              <telegram-auth v-if="isMobile && route && route.name !== 'Main' && route.name !== 'Collections' && (!collectionItem.id || isAuthenticated) && !configData.disable_telegram_auth"></telegram-auth>

            <!-- <telegram-auth v-if="!articleItem.id"></telegram-auth>
             -->
            <!-- Здесь размещаем содержимое меню -->
            <div v-if = "isAuthenticated && articleItem.disable_topmenu_buttons !== true">
              <router-link to="/channels">
                <button class="btn btn btn-outline-primary btn-block w-100 mt-2" v-if="configData && !configData.hide_channels_menu_btn">Мои каналы</button>
              </router-link>

              <router-link to="/collections">
                <button class="btn btn btn-outline-primary btn-block w-100 mt-2"  v-if="configData && !configData.hide_collections_menu_btn">Мои курсы иплейлисты</button>
              </router-link>

              <router-link to="/materials">
                <button class="btn btn btn-outline-primary btn-block w-100 mt-2">Мои материалы</button>
              </router-link>

              <a v-if="articleItem.hasTest" href="#test" class="btn btn-primary btn-block w-100" @click="closeMenu">Тест к материалу</a>
            </div>
<!--             <a v-if="articleItem.hasTest" href="#test" class="btn btn-primary btn-block w-100" @click="closeMenu">Тест к материалу</a>
            <a v-if="articleItem.hasTimecodes" href="#timecodes" class="btn btn-primary w-100" @click="closeMenu">Таймкоды</a> -->
            <!-- <a href="#" class="btn btn-success w-100 mt-2" @click="showSupportModal = true">Поддержать проект</a> -->

          </div>
        </div>
      </div>
    </div>
     <overlay-component :visible="showMenu"></overlay-component>

    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showSupportModal }">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Поддержать проект "Testube"</h5>
            <button type="button" class="btn btn-light" @click="showSupportModal = false">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <p>
              Проект "Testube" стремится предоставить высококачественное и интерактивное образование для всех пользователей. Мы постоянно работаем над развитием платформы, созданием новых образовательных материалов и улучшением пользовательского опыта. Однако, чтобы продолжать развиваться и предоставлять лучший сервис, мы нуждаемся в вашей поддержке.
            </p>
            <div class="alert alert-info">
              <h5>Реквизиты для поддержки</h5>
              <p class="">Тинькофф: <button class="btn btn-light" @click="copy('5536 9137 6657 9771')">5536 9137 6657 9771</button></p>
              <p class="">Газпром Банк: <button class="btn btn-light" @click="copy('6233 7201 7330 3628')">6233 7201 7330 3628</button></p>
              <p class="">СберБанк: <button class="btn btn-light" @click="copy('5469 1100 1528 2353')">5469 1100 1528 2353</button></p>
              <p>Получатель: Ярмоц Алексей</p>
              <p>Назначение платежа: Дарение</p>
            </div>
            <h4>Ваша финансовая поддержка позволит нам:</h4>
            <p>
              
              <ol>
                <li>Расширять библиотеку образовательных видео: Мы будем продолжать добавлять новые видео, покрывающие широкий спектр предметов и тем, чтобы удовлетворить различные интересы и потребности пользователей.</li>
                <li>Улучшать систему генерации тестов и конспектов: Мы сосредотачиваемся на дальнейшем развитии нейросетевых технологий для создания более точных и интерактивных тестов и конспектов, чтобы улучшить процесс обучения и закрепления знаний.</li>
                <li>Развивать сообщество и функциональность: Мы стремимся создать дружественное и активное сообщество, где пользователи могут обмениваться опытом, задавать вопросы и поддерживать друг друга в процессе обучения. Ваша поддержка поможет нам развивать новые функции и улучшать взаимодействие между пользователями.</li>
              </ol>
            </p>
            <p>
              Мы гарантируем, что каждый пожертвованный рубль будет направлен на развитие и совершенствование проекта "Testube" в целях предоставления качественного образовательного контента и улучшения опыта пользователей.
            </p>
            <p>
              Мы искренне благодарим вас за вашу поддержку и содействие в нашем стремлении делать образование доступным и интересным для всех.
            </p>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="showSupportModal = false">Закрыть</button>
          </div>
        </div>
      </div>
    </div>  
     <overlay-component :visible="showSupportModal"></overlay-component>
       
  </div>
</template>

<script>


import { computed } from 'vue';
import { useStore } from 'vuex';
import { mapGetters, mapActions } from 'vuex';
import OverlayComponent from '@/components/OverlayComponent.vue';
import useClipboard from 'vue-clipboard3'
import { useToast } from "vue-toastification";
import TelegramAuth from './TelegramAuth.vue';
import { useRoute } from 'vue-router';

export default {
  components: {
    OverlayComponent,
    TelegramAuth
  },
  data() {
    return {
      showSupportModal: false,
      showMenu: false
    };
  },
  methods: {
    ...mapActions('app', ['setIsMobile']),

    openMenu() {
      this.showMenu = true;
    },
    closeMenu() {
      this.showMenu = false;
    }
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'configData']), 
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters("channel", ["getChannelItem"]),
    ...mapGetters("collection", ["getCollectionItem"]),

    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
    collectionItem: {
      get() {
        return this.getCollectionItem || {};
      },
      set(value) {
        this.updateCollectionItem(value);
      },
    },          
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const article = computed(() => store.getters['article/getArticleItem']);
    const collection = computed(() => store.getters['collection/getCollectionItem']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    //const channel = computed(() => store.getters['channel/getChannelItem']);

    const { toClipboard } = useClipboard()

    const copy = async (text) => {
      try {
        await toClipboard(text)
        //console.log('Copied to clipboard')
        const toast = useToast();

        // or with options
        toast.success("Скопировано в буфер обмена", {
          timeout: 2000
        });
      } catch (e) {
        console.error(e)
      }
    }

    return {
      article, collection, copy, isAuthenticated, route
    };
  },
  mounted() {
    // Проверьте ширину окна при загрузке компонента
    this.setIsMobile(window.innerWidth < 768);

    // Следите за изменением ширины окна и обновляйте параметр isMobile при необходимости
    window.addEventListener('resize', () => {
      this.setIsMobile(window.innerWidth < 768);
    });
  }  
};
</script>

<style scoped>
/* Add any scoped styles specific to the HeaderComponent here */
.btn-gradient {
  overflow: hidden;
  background: linear-gradient(45deg, #9045ce, #b855bb, #ffaed8, #8a43d1);
  background-size: 200% 200%;
  animation: gradientAnimation 2s ease-in-out infinite;
  border: 0;
}

.header-logo{
  border-radius: 10px
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
