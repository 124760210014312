<template>
  <div class="pt-4 materials" v-if="!isAuthenticated">
    <h1>Вход в личный кабинет</h1>
    <p>Авторизуйтесь, чтобы получить доступ к вашим материалам</p>

    <telegram-auth v-if="configData && !configData.disable_telegram_auth"></telegram-auth>
  </div>
  <div class="pt-4 materials" v-if="isAuthenticated">
    <h1>Мои материалы</h1>

    <!-- <div class="alert alert-info">Для преобразования видео и аудио-материалов в текст используется telegram-бот <a href="https://t.me/vid2text_bot" target="_blank">@Vid2Text</a>. <a href="">Зачем это нужно? (добавить инструкцию)</a></div> -->

    <button type="button" class="btn btn-primary" @click="showAddEditMaterialModal()">Добавить материал</button>

    <a :href="configData.help_videos.lessons.url" v-if="configData.help_videos" target="_blank" class="btn btn-light ms-2"><i class="bi bi-question-circle"></i> Инструкция</a>

    <div class="materials-filter row mt-1">
      <div class="col-sm-4 d-flex align-items-center mt-1">
        <input type="text" class="form-control" v-model="filter.query" placeholder="Поиск по названию" @change="applyCollectionsFilterQuery">
      </div>


      <div class="col-sm-4 d-flex align-items-center mt-1" v-if="channels.length && !configData.hide_channels_menu_btn">
        <select v-model="filter.channel_id" class="form-select" @change="applyCollectionsFilterChannel()">
          <option :value="-1">Все каналы</option>
          <option v-for="channelOption in channels" :key="channelOption.id" :value="channelOption.id">{{ channelOption.caption }}</option>
        </select>
      </div>
      <div class="col-sm-4 mt-1">
        <select v-model="filter.category_id" class="form-select" @change="applyCollectionsFilterCategory()" v-if="channels[filter.channel_index]" >
          <option value="-1">Показать все</option>
          <option value="-1" disabled  v-if="channels[filter.channel_index].categories.length">Категории</option>
          <option v-for="item in channels[filter.channel_index].categories" :key="item.id" :value="item.id">{{ item.caption }}</option>

          <option value="-1" disabled  v-if="channels[filter.channel_index].collections.length">Плейлисты</option>
          <option v-for="item in channels[filter.channel_index].collections" :key="item.id" :value="item.id">{{ item.caption }}</option>
        </select>
      </div>
    </div>
      
    <spinner-component :visible="listLoading" class="mt-4"></spinner-component>

    <user-info :user_info="user_info"></user-info>


<ul class="list-group mt-3">
 <li v-for="(material) in materials" :key="material.id" class="list-group-item px-2 px-md-3">
  <div class="d-flex flex-column flex-md-row justify-content-between align-items-start">
    <!-- Левая колонка (изображение и заголовок) -->
    <div class="d-flex flex-column flex-md-row align-items-start w-100 me-2">
      <div v-if="!material.thumbs" class="col-auto col-md-2 px-0 me-md-2">
        <img :src="material.smallpicture" alt="" class="thumb me-2" v-if="material.smallpicture">
        <div class="thumb empty-thumb" v-if="!material.smallpicture"><i class="bi bi-camera"></i></div>
      </div>
      <div v-if="material.thumbs" class="col-auto col-md-2 px-0 me-md-2">
        <div v-if="material.thumbs.middle">
          <img :src="material.thumbs.middle" alt="" class="thumb me-2">
        </div>
      </div>
      <div class="col">
<div class="d-flex flex-column flex-md-row mt-2 mt-md-0 mb-2">
    <div class="d-flex flex-row me-2 mb-2 mb-md-0">
        <div class="text-muted small me-2 "><i class="bi bi-calendar"></i> {{ material.updated_at }}</div>
        <div class="text-muted small me-2 " v-if="material.created_by"><i class="bi bi-person"></i> <span v-html="material.created_by"></span></div>
    </div>
    <div class="d-flex flex-row me-2  ">
        <div class="text-success small me-2 " v-if="material.charges.total_sum_final"><i class="bi bi-credit-card"></i> {{ material.charges.total_sum_final }} ₽</div>
        <div class="text-info small me-2 " v-if="material.duration_minutes"><i class="bi bi-clock"></i> {{ material.duration_minutes }}</div>
    </div>
</div>




        <div class="text text-info text-sm" v-if="material.status == 'downloading'">⌛ Скачивается</div>
        <div class="text text-info text-sm" v-if="material.status == 'processing'">⌛ Обрабатывается</div>
        <div v-if="material.status == 'failed'">
          <div class="text text-warning text-sm">❌ Ошибка! {{ material.status_comment }}</div>
        </div>
        <div v-if="material.status == 'confirmation'">
          <div class="text text-warning text-sm">🔔 Подтвердите задачу в чат-боте</div>
        </div>

        <div class="d-flex">
          <h5 class="mb-0" v-if="material.caption"> 
            <a :href="material.link" v-if="!isPreview">{{ material.caption }}</a>
            <span v-if="isPreview">{{ material.caption }}</span>
          </h5>
          <div class="xs text-muted ms-1" v-if="configData.is_moderator">{{material.id}}</div>
        </div>
        <div class="mt-2 mb-2" v-if="isPreview">
          <a class="btn btn-primary btn-block w-100 mb-1" :href="material.link + '?preview=article'">Показать статью</a>
          <a class="btn btn-primary btn-block w-100 mb-1" :href="material.link + '?preview=summary'" v-if="material.has_summary">Показать статью</a>
          <a class="btn btn-primary btn-block w-100 mb-1" :href="material.link + '?preview=test'" v-if="material.has_test">Показать тест</a>
          <a class="btn btn-primary btn-block w-100 mb-1" :href="material.link + '?preview=timecodes'" v-if="material.has_timecodes">Показать тест</a>
          <a class="btn btn-primary btn-block w-100 mb-1" :href="material.link + '?preview=checklist'" v-if="material.has_checklist">Показать чек-лист</a>

          <button @click="showMaterialInBot(material)" class="btn btn-outline-primary btn-block w-100">Открыть в боте</button>
        </div>
        <div v-if="material.warning_not_fully_processed">
          <div class="text text-warning small">Материал обработан не полностью! Перезапустите или удалите задачу – баланс будет восстановлен.</div>
          <button @click="restartTask(material)" class="btn btn-warning btn-sm">Перезапустить задачу</button>
        </div>
        <div class="d-flex flex-wrap mt-1 align-items-center" v-if="!configData.hide_channels_menu_btn">
          <div class="mt-1" v-if="channels.length">
            <select v-model="material.channel_id" class="form-select" @change="saveMaterialChannel(material)">
              <option :value="0">Без канала</option>
              <option v-for="channelOption in channels" :key="channelOption.id" :value="channelOption.id">{{ channelOption.caption }}</option>
            </select>
          </div>
          <div class="ms-1 mt-1">
            <select v-model="material.category_id" class="form-select" @change="saveMaterialCategory(material)" v-if="material.channelIndex !== -1 && channels[material.channelIndex] && channels[material.channelIndex].categories.length">
              <option value="-1">Без категории</option>
              <option v-for="item in channels[material.channelIndex].categories" :key="item.id" :value="item.id">{{ item.caption }}</option>
            </select>
          </div>
        </div>
        <div class="d-flex flex-wrap mt-1">
          <!-- Вывод информации о плейлистах -->
        </div>
        <div class="d-flex flex-wrap mt-1">
          <span class="badge bg-primary me-1" v-if="material.has_text && material.creative_mode">Оригинальный текст</span>
          <span class="badge bg-primary me-1" v-if="material.has_text && !material.creative_mode">Креативный текст</span>
          <span class="badge bg-primary me-1" v-if="material.has_summary">Конспект</span>
          <span class="badge bg-primary me-1" v-if="material.has_timecodes">Таймкоды</span>
          <span class="badge bg-primary me-1" v-if="material.has_checklist">Чек-лист</span>
          <span class="badge bg-warning" v-if="material.collections.length > 1">в {{ material.collections.length }} плейлистах</span>
          <span class="badge bg-warning" v-if="material.collections.length == 1">в плейлисте: {{ material.collections[0].caption }}</span>
        </div>
      </div>
    </div>
    <!-- Правая колонка (действия) -->
    <div class="material-actions text-nowrap mt-2 mt-md-0">
      <button @click="toggleFree(material)" class="btn btn-outline-success me-2" v-if="material.channel_id == 59 && material.is_free">
        <span>FREE</span>
      </button>
      <button @click="toggleFree(material)" class="btn btn-light me-2" v-if="material.channel_id == 59 && !material.is_free">
        <span>💰 платно</span>
      </button>
      <button @click="restartTask(material)" class="btn btn-danger me-2" v-if="(material.status == 'failed' && !material.restarting) || material.allow_restart">
        <i class="bi bi-arrow-clockwise"></i>
      </button>
      <button @click="toggleVisibility(material)" class="btn" :class="(material.visibility)?'btn-outline-success':'btn-outline-warning'" v-if="!configData.hide_article_visibility_btn">
        <i class="bi bi-eye" v-if="material.visibility == 1"></i>
        <i class="bi bi-eye-slash" v-if="material.visibility == 0"></i>
      </button>

      <button @click="showMaterialLog(material)" class="btn btn-outline-primary ms-2" v-if="configData.is_moderator">
        <i class="bi bi-info"></i>
      </button>

      <button @click="showAddEditMaterialModal(material)" class="btn btn-outline-primary ms-2" v-if="!configData.hide_article_settings_btn">
        <i class="bi bi-gear"></i>
      </button>
      <button @click="confirmDelete(material)" class="btn btn-outline-danger ms-2">
        <i class="bi bi-trash"></i>
      </button>
    </div>
  </div>
</li>
</ul>


<!--     <div class="mt-3 alert alert-info">
      <p>Добавить новые материалы вы можете через чат-бот. Просто добавьте видео или аудио на преобразование в текст. После обработки тут вы сможете отредактировать текст материала, а также тест к нему при необходимости.</p>
      <a class="btn btn-primary" href="https://t.me/vid2text_bot?start=ref_v2t_site">Перейти в чат-бот</a>
    </div>
 -->
    <!-- Modal window for creating a collection -->

          <AddMediaModalComponent
            v-if="showAddMediaModal && configData.service_name == 'narezkavideo'"
            :show-modal="showAddMediaModal"
            :user="user"
            :article-item="materialItem"
            :media-uploading="mediaUploading"
            @cancel="cancelAddMediaModal"
            @close="closeAddMediaModal"
            @save="handleAddMediaSave"
          />

    <!-- Модальное окно для отображения логов материала -->
    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showLogsModal }">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Логи материала</h2>
            <button type="button" class="btn btn-light" @click="closeLogsModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="materialLogs && materialLogs.length > 0">
              <ul class="list-group">
                <li v-for="log in materialLogs" :key="log.id" class="list-group-item">
                  {{ log.updated_at }} — {{ log.status }} — {{ log.status_comment }}
                </li>
              </ul>
            </div>
            <div v-else>
              <p>Логи не найдены для этого материала.</p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showSettingsModal }" v-if="configData.service_name !== 'narezkavideo'">
      <div class="modal-dialog modal-lg">
        <div class="modal-content" >
          <div class="modal-header">
            <h2 class="modal-title">Настройки материала</h2>
            <button type="button" class="btn btn-light" @click="cancelMaterial">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-group mb-2">
                <label for="title" class="strong">Название:</label>
                <!-- <p v-if="materialItem.id">{{materialItem.caption}}</p> -->
                <input type="text" id="caption" class="form-control" v-model="materialItem.caption" required>
              </div>


              <div class="form-group mb-2" v-if="materialItem.id">
                <label for="description" class="">Короткое описание (не обязательно):</label>
                
                <textarea type="text" id="description" class="form-control" v-model="materialItem.description" required></textarea>
              </div>

              <div class="form-group mb-2" >
                <label for="channel" class="">Выберите канал (не обязательно):</label>
                <select class="form-control" v-model="selectedChannel" @change="selectChannelForMaterial">
                  <option value="">Выберите канал</option>
                  <option v-for="channel in channels" :key="channel.id" :value="channel.id">{{ channel.caption }}</option>
                </select>
              </div>

              <div class="form-group mb-2" v-if="selectedChannel">
                <label for="collection" class="">Выберите плейлист или курс:</label>
                <select class="form-control" v-model="selectedCollection" @change="addCollectionToMaterial">
                  <option value="">Выберите плейлист</option>
                  <option v-for="collection in collections" :key="collection.id" :value="collection.id">{{ collection.caption }}</option>
                </select>
              </div>


              <div class="collections mt-3" v-if="materialItem.collections && materialItem.collections.length">
                <!-- <div class="strong">Добавлено в плейлисты:</div> -->
                <div class="list-group">
                  <div
                    v-for="collection in materialItem.collections"
                    :key="collection.collection_id"
                    class="list-group-item d-flex justify-content-between align-items-center"
                  >
                    {{ collection.caption }}
                    <div @click="removeMaterialFromCollection(materialItem.id, collection.collection_id)" class="btn btn-danger btn-sm">
                      Удалить
                    </div>
                  </div>
                </div>
              </div>


              <div class="mt-4 mb-4" v-if="materialItem.id">
                <spinner-component :visible="coverUploading"></spinner-component>

                <div class="form-group mb-2" v-if="materialItem.thumbs && materialItem.thumbs.big">
                  <label for="coverImage">Обложка</label>
                  <div>
                    
                    <img :src="materialItem.thumbs.big" class="cover"/>
                  </div>
                </div>
                <div class="form-group mb-2">
                  <label for="coverImage">Загрузить обложку (рекомендуемый размер 1920x1080)</label>
                  <div><input type="file" id="coverImage" @change="handleCoverImageChange" accept="image/*"></div>
                </div>
              </div>  

              <!-- <button type="button" class="btn btn-primary" @click="showAddMediaModal = true">Добавить видео/аудио</button> -->


              <div v-if="!materialItem.id" class="mt-4 mb-4">
                <div  class="editor-container">
                  <QuillEditor
                    theme="snow"
                    v-model:content="materialItem.content"
                    contentType="html"
                    :toolbar="[
                      { 'header': [2, 3, 4, false] },
                      'bold',
                      'italic',
                      'underline',
                      'blockquote',
                      { list: 'ordered' },
                      { list: 'bullet' },
                      'clean',
                      'html'
                    ]"
                  />
                </div>
              </div>

              <div class="mt-1 d-flex justify-content-between align-items-center">
                <button type="button" class="btn btn-primary" @click="saveMaterialSettings">Сохранить</button>
                <button type="button" class="btn btn-secondary" @click="cancelMaterial">Закрыть</button>
              </div>
            </form>
          </div>
        </div>      
      </div>

          <AddMediaModalComponent
            :show-modal="showAddMediaModal"
            :user="user"
            :article-item="materialItem"
            :media-uploading="mediaUploading"
            @cancel="cancelAddMediaModal"
            @save="handleAddMediaSave"
          />

    </div>
    <overlay-component :visible="showSettingsModal"></overlay-component>


  </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, useStore} from 'vuex';
import { computed, watchEffect, getCurrentInstance} from 'vue';

import { useToast } from "vue-toastification";
import OverlayComponent from '@/components/OverlayComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import { QuillEditor } from '@vueup/vue-quill'
import AddMediaModalComponent from '@/components/AddMediaModalComponent.vue'; // Путь к вашему компоненту AddMediaModal.vue
import TelegramAuth from './TelegramAuth.vue';
import UserInfo from '@/components/UserInfo.vue';

export default {
  data() {
    return {
      mediaUploading: false,
      showAddMediaModal: false,
      editingMaterialId: null,
      activeTab: 'settings',
      showSettingsModal: false,
      selectedChannel: null,
      selectedCollection: null,
      coverUploading: false,
      user_info: null,
      filter: {channel_id: -1, category_id: -1},
      listLoading: false,
      //collections: [],
      showLogsModal: false, // Управление видимостью модального окна логов
      materialLogs: [], // Массив для хранения логов материала

    };
  },
  components: {
      OverlayComponent,
      SpinnerComponent,
      QuillEditor,
      AddMediaModalComponent,
      TelegramAuth,
      UserInfo
  },
  computed: {
    ...mapGetters('collection', ['getCollectionItem', 'getCollections']),
    ...mapGetters('material', ['getMaterialItem', 'materials']),
    ...mapGetters('channel', ['getChannelItem', 'getChannels']),
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview']), 

    materialItem: {
      get() {
        return this.getMaterialItem || {};
      },
      set(value) {
        this.updateMaterialItem(value);
      },
    },
    
    collections: {
      get() {
        return this.getCollections || [];
      },
      set(value) {
        this.$store.commit('collection/setCollections', value);
      },
    },
    channels: {
      get() {
        return this.getChannels || [];
      },
      set(value) {
        this.$store.commit('channel/setChannels', value);
      },
    },
    processedPercentage() {
      if (!this.user_info || this.user_info.materials.total === 0) {
        return 0;
      }
      return Math.round((this.user_info.materials.statuses.processed / this.user_info.materials.total) * 100);
    }    
  },  
  setup(){
    const store = useStore();
    const user = computed(() => store.getters['user/getUser']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const instance = getCurrentInstance();
    watchEffect(() => {
      //console.log(isAuthenticated.value);
      if (isAuthenticated.value && instance.proxy.fetchMaterials) {
        // User is authenticated, fetch article
        instance.proxy.fetchMaterials();
      }
    });

    return {
      user,
      isAuthenticated
    }
  },  
  created() {
    if (this.configData.service_name == 'narezkavideo'){
      this.viewMode = '';
      this.setPreview('');
    }    
    // Вызов экшена для загрузки подборки
    this.fetchMaterials();
    this.fetchChannels();
  },  
  methods: {
    ...mapActions('app', ['setPreview']),

    ...mapMutations('collection', ['setCollectionItem', 'setCollections']),
    ...mapActions('collection', ['fetchCollection', 'saveCollection', 'editCollection', 'deleteCollection', 'fetchCollections', 'saveCollectionCoverImage', 'fetchCategories']),

    ...mapMutations('channel', ['setChannelItem', 'setChannels']),
    ...mapActions('channel', ['fetchChannel', 'saveChannel', 'deleteChannel', 'editMaterial', 'deleteMaterial', 'getMaterialsForChannel', 'fetchChannels', 'saveChannelCoverImage']),
    
    ...mapMutations('material', ['setMaterials', 'setMaterialItem']),
    ...mapActions('material', ['deleteMaterial', 'fetchMaterials', 'saveMaterialSettings', 'removeCollectionFromMaterial','saveMaterialCoverImage']),
    // editMaterial(material) {
    //   // Установка ID редактируемой подборки и заполнение полей формы данными
    //   this.editingMaterialId = material.id;

    //   // Вызов мутации `setMaterialItem` из модуля `material`
    //   this.$store.commit('material/setMaterialItem', material);

    //   // Отображение модального окна для редактирования подборки
    //   this.showCreateModal = true;
    // },

    editMaterial(material) {
      // Установка ID редактируемой подборки и заполнение полей формы данными
      this.editingMaterialId = material.id;

      // Вызов мутации `setMaterialItem` из модуля `material`
      this.setMaterialItem(material);

      // Отображение модального окна для редактирования подборки
      this.showSettingsModal = true;
    },

    confirmDelete(material) {
      if (window.confirm('Подтверждаете удаление?')) {
        this.deleteMaterial(material);
      }
    },

    restartTask(material){

      material.restarting = true;
      this.setMaterialItem(material);

      this.$store.dispatch('material/restartMaterial', material)
        .then((data) => {
          console.log(data);
          const toast = useToast();
          toast.success('Задача перезапущена', {
            timeout: 2000,
          });

          material.restarting = false;

          this.setMaterialItem(data.material);
          
          // Find the index of the updated material in the materials array
          const materialIndex = this.materials.findIndex(mat => mat.id === data.material.id);

          if (materialIndex !== -1) {
            // Update the cover image URL in the materials array
            this.materials[materialIndex] = data.material;
          }

          this.setMaterials(this.materials);
          this.$forceUpdate();

        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при удалении
        });

      console.log('Material restarted successfully');

    },


    async handleCoverImageChange(event) {
      const file = event.target.files[0];
      //console.log(file);
      if (!file) return; // Если файл не выбран, ничего не делаем

      // Подготавливаем файл для загрузки с помощью FormData
      const formData = new FormData();
      formData.append('coverImage', file);
      //console.log(formData);

      this.coverUploading = true;
      console.log(this.materialItem);
      try {
        // Вызываем экшен для сохранения обложки на сервере
        const response = await this.saveMaterialCoverImage({
          materialId: this.materialItem.id,
          coverImageForm: formData
        });

        this.setMaterialItem(response.material);
        
        // Find the index of the updated material in the materials array
        const materialIndex = this.materials.findIndex(mat => mat.id === response.material.id);

        if (materialIndex !== -1) {
          // Update the cover image URL in the materials array
          this.materials[materialIndex].thumbs = response.material.thumbs;
        }

        console.log(response);
        this.coverUploading = false;
      } catch (error) {
        this.coverUploading = false;
        // Обработка ошибки, если произошла ошибка при сохранении обложки
        console.error('Error uploading cover image:', error);
      }
    },

    saveAddMedia(){
      this.showAddMediaModal = false; 
      //this.saveArticle();
    },

    cancelAddMediaModal(){
      this.showAddMediaModal = false; 
    },

    closeAddMediaModal(){
      this.showAddMediaModal = false; 
      console.log('fetchMaterials');
      this.fetchMaterials();
    },

    handleShowAddMediaModal(){
      this.showAddMediaModal = true;
    },

    handleAddMediaSave(){
      //this.fetchArticle();
    },

    showMaterialLog(material) {
      this.setMaterialItem(material);
      this.$store.dispatch('material/getMaterialLogs', material.id) // Замените на ваш запрос
        .then((data) => {
          this.materialLogs = data; // Сохранение логов для отображения
          this.showLogsModal = true; // Показать модальное окно
        })
        .catch((error) => {
          console.log(error); // Обработка ошибок
        });
    },
    // Метод для закрытия модального окна
    closeLogsModal() {
      this.showLogsModal = false;
    },

    deleteMaterial(material) {
      this.setMaterialItem(material);
      this.$store.dispatch('material/deleteMaterial', material)
        .then((data) => {
          console.log(data);
          const toast = useToast();
          toast.success('Материал удален', {
            timeout: 2000,
          });

          // Найти и удалить коллекцию из массива materials
          const index = this.materials.findIndex(coll => coll.id === material.id);
          if (index !== -1) {
            this.materials.splice(index, 1);
          }

          // Обновление текущей редактируемой подборки, если она совпадает с удаляемой
          if (this.materialItem && this.materialItem.id === material.id) {
            this.setMaterialItem(null);
          }
        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при удалении
        });

      console.log('Material deleted successfully');
    },  



    async saveMaterialCategory(material) {
      this.setMaterialItem(material);

      const updatedMaterial = {
        id: this.materialItem.id,
        categoryId: this.materialItem.category_id
      };
          
      try {
          const data = await this.$store.dispatch('material/saveMaterialCategory', updatedMaterial);
          console.log(data);
          const toast = useToast();
          toast.success('Категория материала сохранена', {
            timeout: 2000,
          });
      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },    


    async saveMaterialChannel(material) {
      this.setMaterialItem(material);
      

      const updatedMaterial = {
        id: this.materialItem.id,
        channelId: this.materialItem.channel_id
      };

     this.$store.dispatch('collection/fetchCategories', this.materialItem.channel_id)
      .then(() => {

        const channelIndex = this.channels.findIndex(ch => ch.id === this.materialItem.channel_id);

        this.materialItem.channelIndex = channelIndex;

        console.log(channelIndex);

        //if (channelIndex !== -1) {

        
      })
      .catch(error => {
        console.log(error);
      });

          
      try {
          const data = await this.$store.dispatch('material/saveMaterialSettings', updatedMaterial);
          console.log(data);
          const toast = useToast();
          toast.success('Настройки материала сохранены', {
            timeout: 2000,
          });
      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },    

    async saveMaterialSettings() {
      console.log(this.materialItem);

      const updatedMaterial = {
        id: this.materialItem.id,
        caption: this.materialItem.caption,        
        description: this.materialItem.description,        
        content: this.materialItem.content,        
        channelId: this.materialItem.channel_id,
        collections: this.materialItem.collections
      };

            
      try {
          const data = await this.$store.dispatch('material/saveMaterialSettings', updatedMaterial);
          console.log(data);

          if (!updatedMaterial.id){
            
            this.fetchMaterials();
            const toast = useToast();
            toast.success('Новый материал добавлен', {
              timeout: 2000,
            });
          } else {
            this.cancelMaterial();
            const toast = useToast();
            toast.success('Настройки материала сохранены', {
              timeout: 2000,
            });
          }

      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },

    fetchMaterials(payload) {
      console.log(payload);

      this.listLoading = true;
      this.setMaterials([]);
      this.$store.dispatch('material/fetchMaterials', payload)
        .then((data) => {
          //console.log(data);

          if (typeof data.user_info !== 'undefined') this.user_info = data.user_info;
            
          var materials = data.materials;
          materials.forEach((material) => {
            const channelIndex = this.channels.findIndex(ch => ch.id === material.channel_id);
            material.channelIndex = channelIndex;
          });
  
          //console.log(materials);
          this.setMaterials(materials);
          this.$forceUpdate();
          this.listLoading = false;
          // Обработка успешной загрузки списка подборок
          //this.materials = data.list;
        })
        .catch((error) => {
          console.log(error);
          this.listLoading = false;
          // Обработка ошибок при загрузке списка подборок
        });
    },

    cancelMaterial() {
      this.showSettingsModal = false;
      this.setMaterialItem(null); // Обновление связанного объекта collectionItem
    },




   async toggleFree(material) {
      
      if (material.is_free == 0){
        material.is_free = 1;
      } else {
        material.is_free = 0;
      }
      
      console.log(material.is_free);
      //this.setMaterialItem(material);

      const updatedMaterial = {
        id: material.id,
        is_free: material.is_free
      };
          
      try {
          await this.$store.dispatch('material/saveMaterialSettings', updatedMaterial);
          const toast = useToast();

          if (material.is_free == 0){
            toast.success('Материал платный', {
              timeout: 2000,
            });
          } else if (material.is_free == 1){
            toast.success('Материал бесплатный', {
              timeout: 2000,
            });
          }
      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },   

    async toggleVisibility(material) {
      
      if (material.visibility == 0){
        material.visibility = 1;
      } else {
        material.visibility = 0;
      }
      
      console.log(material.visibility);
      //this.setMaterialItem(material);

      const updatedMaterial = {
        id: material.id,
        visibility: material.visibility
      };
          
      try {
          await this.$store.dispatch('material/saveMaterialSettings', updatedMaterial);
          const toast = useToast();

          if (material.visibility == 0){
            toast.success('Материал видите только вы', {
              timeout: 2000,
            });
          } else if (material.visibility == 1){
            toast.success('Материал видят все', {
              timeout: 2000,
            });
          }
      } catch (error) {
        console.log(error);
        // Обработка ошибок при сохранении
      }

    },   

    showAddEditMaterialModal(material = null) {
      // Reset the form fields
      //this.materialForm.title = '';
      //this.materialForm.description = '';

      console.log(material);
      // Check if material parameter is provided for editing
      if (material) {
         this.setMaterialItem(material);
         console.log(material.channel_id);

         this.selectedChannel = material.channel_id;
         this.selectChannelForMaterial();

      } else {
        this.editingMaterialId = null; // Reset the editingMaterialId if creating a new material
        material = {};
        material.caption = material.content = '';
        material.collections = [];
        this.setMaterialItem(material);

      }

      //this.$store.dispatch('material/clearMaterialMaterials');

      // Show the modal window
      
      if (this.configData.service_name == 'narezkavideo'){
        this.showAddMediaModal = true;
      } else {
        this.showSettingsModal = true;  
      }
    },

    fetchChannels() {
      this.$store.dispatch('channel/fetchChannels')
        .then((data) => {
          //console.log(data);
          this.setChannels(data.channels);

          //console.log(this.materials);

          // this.materials.forEach((material) => {
          //   var ind = this.channels.findIndex(ch => ch.id === material.channel_id);
          //   //if (ind == -1)
          // })
          // console.log(this.materials);

          // Обработка успешной загрузки списка подборок
          //this.channels = data.list;
        })
        .catch((error) => {
          console.log(error);
          // Обработка ошибок при загрузке списка подборок
        });
    },

    applyCollectionsFilterQuery(){
      this.filter.channel_index = -1;
      this.filter.channel_id = -1;
      this.filter.category_id = -1;
      this.fetchMaterials({query: this.filter.query});
      //this.loadCollections();
    },



    applyCollectionsFilterChannel(){
      this.filter.channel_index = this.channels.findIndex(ch => ch.id === this.filter.channel_id);

      this.fetchMaterials({channelId: this.filter.channel_id});
      //this.loadCollections();
    },

    applyCollectionsFilterCategory(){
      this.filter.category_index = this.channels[this.filter.channel_index].categories.findIndex(ch => ch.id === this.filter.category_id);
      this.fetchMaterials({channelId: this.filter.channel_id, 'categoryId': this.filter.category_id});

    },

    removeMaterialFromCollection(materialId, collectionId) {
      this.$store.dispatch('material/removeCollectionFromMaterial', collectionId)
        .then(() => {

          // const toast = useToast();
          // toast.success("Материал удален из плейлиста", {
          //   timeout: 2000,
          // });
          
          // Если необходимо, обновите список материалов в данной коллекции
          // Например, вызовите метод для загрузки материалов для данной коллекции
          //this.loadCollectionMaterials(collectionId);
        })
        .catch(error => {
          console.error('Error removing material from collection:', error);
          // Обработка ошибки при удалении
        });
    },

    addCollectionToMaterial() {
      if (this.selectedCollection) {
        const collectionToAdd = this.collections.find(collection => collection.id === this.selectedCollection);
        if (collectionToAdd) {
          // Проверка, чтобы не добавить плейлист, который уже есть в списке
          const existingCollection = this.materialItem.collections.find(collection => collection.collection_id === this.selectedCollection);
          if (!existingCollection) {
            this.materialItem.collections.push({
              collection_id: collectionToAdd.id,
              caption: collectionToAdd.caption,
              // ... другие необходимые свойства
            });
          }
        }
      }
      // Сброс выбранного плейлиста после добавления
      this.selectedCollection = null;
    },

    showMaterialInBot(material){
      this.$store.dispatch('tgconnector/sendCommandToBot', {
        params: { material_hash: material.hash, command: 'show_material'}
      });

    },

    selectChannelForMaterial(){
      console.log(this.selectedChannel);
      
      this.materialItem.channel_id = this.selectedChannel;
      this.setMaterialItem(this.materialItem);


      this.loadCollections();
    },

    loadCollections(payload) {

      console.log(this.selectedChannel);

      if (this.selectedChannel) {
        //payload = this.selectedChannel;
        payload = { channelId: this.selectedChannel }
      }

      if (payload){
        this.$store.dispatch('collection/fetchCollections', payload)
          .then((data) => {
            //console.log(data.collections);
            //this.collections = data.collections;
            this.setCollections(data.collections);
          })
          .catch(error => {
            console.log(error);
          });
      }
    
    },

  }
};
</script>

<style>
/* Component styles here */
.cover{
  width: 300px;
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

.materials .thumb{
  width: 100%;
  height: auto;
  border-radius: 8px;
  margin-right: 6px;
}

.materials .empty-thumb{
  background-color: #EFEFEF;
  height: 80px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.strong{
  font-weight: 600;
}

  .info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .info-item {

    border: 1px solid;
    padding: 20px;
    border-radius: 8px;
  }

  .info-item p{
    margin-bottom: 0;
  }
  .info-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .info-item {
    
    flex: 1 1 calc(50% - 20px);
    box-sizing: border-box;
    padding: 10px;
    border: 1px solid;
    border-radius: 5px;
  }
  @media (min-width: 600px) {
    .info-item {
      flex: 1 1 calc(50% - 20px); /* Two items per row */
    }
  }
  @media (min-width: 900px) {
    .info-item {
      flex: 1 1 calc(20% - 20px); /* Three items per row */
    }
  }  

  .material-actions {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
  }
  @media (min-width: 768px) {
    .material-actions {
      justify-content: flex-start;
    }
  }  
</style>
