<template>

  <div class="row mb-3" :class="!isPreview?'mt-4':''">
    <div class="col-lg-8" :class="(isPreview == 'timecodewords')?'col-lg-12':''">
      <div class="shadow rounded mb-3" :class="!isPreview?'p-4 pt-4':'p-0 pt-0'">

      <div class="text-center">
        <spinner-component :visible="!articleItem.id" class="mt-4"></spinner-component>
      </div>


      <div class="container" :class="{
        'protect_content': articleItem.protect_content && !hasEditAccess,
        'p-0': isPreview
      }">       

        <div class="d-flex justify-content-between align-items-start mb-3" v-if="isPreview">
          <h1 class="mb-0">{{ articleItem.title }}</h1>
          <div :class="'badge report-score bg-' + articleItem.report_score.color_class" class="mt-0" v-if="articleItem.report_score">
            <i :class="articleItem.report_score.icon"></i> {{articleItem.report_score.good_percent}}%
          </div>
        </div>

        <div v-if="!editingMode && !isPreview" class="mb-3">
          <div class="breadcrumbs text text-muted text-sm" v-if="articleItem.collection_breadcrumbs" v-html="articleItem.collection_breadcrumbs"></div>
          <div class="video-container mb-3" v-if="articleItem.youtube_link">
            <iframe class="my-2 video" v-if="articleItem.youtube_link" :src="articleItem.youtube_link" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
          </div>

          <div v-if="!articleItem.telegram_item_link && !articleItem.tg_video_link && articleItem.id" class="mb-2">
            <h1 >{{ articleItem.title }}</h1>

            <div class="d-flex relative">
<!--               <a :href="articleItem.telegram_edit_link" target="_blank" class="ms-1 btn btn-primary btn-gradient" v-if="hasEditAccess && articleItem.telegram_edit_link">
                Открыть в Telegram
              </a>
 -->
            </div>


          </div>



          <div class="relative" v-if="!articleItem.youtube_link && articleItem.cover_image">
            
            <!-- <a :href="articleItem.tg_video_link"  target="_blank"  v-if="articleItem.tg_video_link" class="btn btn-primary btn-tg-video btn-gradient"><i class="bi bi-play-btn-fill"></i> Смотреть видео в Telegram</a> -->

            <a :href="articleItem.telegram_item_link" target="_blank" v-if="articleItem.telegram_item_link && !articleItem.tg_video_link" class="btn btn-primary btn-tg-video btn-gradient"><i class="bi bi-play-btn-fill"></i> Открыть в Telegram</a>

            <a :href="articleItem.tg_video_link" target="_blank" v-if="!articleItem.telegram_item_link && articleItem.tg_video_link" class="btn btn-primary btn-tg-video btn-gradient"><i class="bi bi-play-btn-fill"></i> Открыть в Telegram</a>

            <img :src="articleItem.cover_image" class="w-100 rounded"/>
          </div>
          <div v-else-if="!articleItem.youtube_link && articleItem.telegram_item_link && !articleItem.cover_image && articleItem.title" class="relative" :class="articleItem.chatbot_name !== 'zvonalitik'?'header-block':''">
            <div >

              <!-- <div class="badge bg-primary" v-if="articleItem.material_data">{{articleItem.material_data.call_purpose_caption}}</div> -->
              <div class="d-flex justify-content-between align-items-start">
                <h1>{{ articleItem.title }}</h1>
                <div :class="'badge report-score bg-' + articleItem.report_score.color_class" v-if="articleItem.report_score">
                  <i :class="articleItem.report_score.icon"></i> {{articleItem.report_score.good_percent}}%
                </div>
              </div>

            </div>
            <div class="d-flex" >
              <a :href="articleItem.telegram_item_link" target="_blank" v-if="articleItem.telegram_item_link" class="btn btn-primary btn-gradient"><i class="bi bi-play-btn-fill"></i> Открыть в Telegram</a>  
              <!-- <button class="btn btn-primary ms-2" v-if="hasEditAccess && !articleItem.disable_manage_buttons" @click="handleShowAddMediaModal()">Заменить видео/аудио</button> -->
            </div>
          </div>

          <div class="mt-3">
              <button class="btn btn-primary w-100" v-if="hasEditAccess && !articleItem.disable_manage_buttons && (articleItem.tg_video_link !=='' || articleItem.youtube_link !== '')" @click="handleShowAddMediaModal()"><i class="bi bi-film"></i>  Заменить видео/аудио</button>
              <button class="btn btn-primary w-100" v-if="hasEditAccess && !articleItem.disable_manage_buttons && (articleItem.tg_video_link == '' && articleItem.youtube_link == '')" @click="handleShowAddMediaModal()"><i class="bi bi-film"></i>  Прикрепить видео/аудио</button>


          </div>
          <hr v-if = "articleItem.id">
        </div>
        


      <AddMediaModalComponent
        :show-modal="showAddMediaModal"
        :user="user"
        :article-item="articleItem"
        :media-uploading="mediaUploading"
        @cancel="cancelAddMediaModal"
        @save="handleAddMediaSave"
      />
<!--         @update:mediaUploading="value => mediaUploading = value"
        @update:articleItem="updatedItem => articleItem = updatedItem"
        
        
 -->

      <!-- <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showAddMediaModal }">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="modal-title">Прикрепить видео/аудио к материалу</h2>
              <button type="button" class="btn btn-light" @click="cancelAddMediaModal">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <form v-if="user">
 
                <div class="alert alert-info" v-if="0" >
                  <p>Укажите ссылку на Youtube-видео или загрузите видео (или аудио) к этому материалу. Текст из медиа-файла будет извлечен автоматически и добавлен в этот материал.</p>
                  <div v-if="articleItem.content">
                    <div>Внимание! При добавлении или изменении видео (или аудио) текущий контент материала будет перезаписан текстом из нового файла. Если хотите сохранить текущий контент, поставьте галочку.</div>

                    <div class="form-check mt-3">
                        <input class="form-check-input" type="checkbox" v-model="articleItem.dont_convert_media" id="dontConvertMedia">
                        <label class="form-check-label" for="dontConvertMedia">
                            Сохранить текущий контент материала.
                        </label>
                    </div>
                  </div>
                </div>

                <div class="form-group mb-2" v-if="!mediaUploading">
                  <label for="mediaFile">Youtube-ссылка</label>
                  <div><input v-model="articleItem.video_url" class="form-control mb-3"></div>
                </div>
                <spinner-component :visible="mediaUploading" class="mt-4"></spinner-component>

                <div class="form-group mb-2" v-if="!mediaUploading">
                  <label for="mediaFile">или загрузите файл (видео или аудио)</label>
                  <div><input type="file" id="mediaFile" @change="handleMediaUpload" accept="video/*,audio/*"></div>
                </div>

                <div class="mt-5 d-flex justify-content-between align-items-center">
                  <button type="button" class="btn btn-primary" @click="saveAddMedia">Сохранить</button>
                  <button type="button" class="btn btn-secondary" @click="cancelAddMediaModal">Закрыть</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      <overlay-component :visible="showAddMediaModal"></overlay-component> -->

        <div class="card" v-if="articleItem.status == 'pending' || articleItem.status == 'processing'">
          <div class="card-body text-center" v-if="articleItem.chatbot_name == 'vid2text'">
            <div><b>Извлекается текст из прикрепленного медиа-файла. Отслеживайте прогресс в <a href="https://t.me/vid2text_bot?start=ref_v2t_site">@Vid2Text_bot</a>. Ожидайте...</b></div>
            <spinner-component :visible="true" class="mt-4"></spinner-component>
            <div class="small text-muted">Эту страницу можно закрыть. Если задача зависла, напишите <a href="https://t.me/ayarmots">@ayarmots</a></div>
          </div>
          <div v-else class="card-body text-center">
            <div><b>Файл в очереди на обработку. Ожидайте...</b></div>
            <spinner-component :visible="true" class="mt-4"></spinner-component>
            <div class="small text-muted">Эту страницу можно закрыть. Если задача зависла, напишите <a href="https://t.me/ayarmots">@ayarmots</a></div>

          </div>
        </div>

        <!-- <div v-if="(articleItem.status == 'draft' || articleItem.status == 'processed' || articleItem.status == 'modeselection')"> -->
        <div v-if="articleItem.status !== 'processsing'">
          <div class="lead mb-4" v-if="articleItem.description">{{articleItem.description}}</div>
          <div v-if="articleItem.forbidden && articleItem.forbidden_title" class="alert alert-warning">
            <h2>{{articleItem.forbidden_title}}</h2>
            <div v-html="articleItem.forbidden_text"></div>
          </div>
            
          <div class="mb-3"  v-if="( (!articleItem.forbidden || hasEditAccess) && !isPreview)">
            <button class="btn me-1 mb-1" :class="(viewMode == 'summary')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'summary'"  v-if="articleItem.text_summary">Конспект</button>
            <button class="btn me-1 mb-1" :class="(viewMode == 'full')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'full'"  v-if="articleItem.content" v-html="articleItem.article_tab_caption"></button>
            <button class="btn me-1 mb-1" :class="(viewMode == 'test')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'test'" v-if="articleItem.hasTest">Пройти тест</button>
            <button class="btn me-1 mb-1" :class="(viewMode == 'checklist')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'checklist'"  v-if="articleItem.text_checklist">Чек-лист</button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'podcast')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'podcast'"  v-if="articleItem.podcast_audio_link">Подкаст</button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'timecodes')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'timecodes'"  v-if="articleItem.hasTimecodes">Таймкоды</button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'dialogue')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'dialogue'" v-if="articleItem.has_dialogue && !channelItem.disable_dialogue_tab">Диалог и темы <span class="badge bg-danger" v-if="articleItem.tags_cnt">{{articleItem.tags_cnt}}</span></button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'transcribe')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'transcribe'" v-if="articleItem.text_fragments_transcriptions && !channelItem.disable_transcrib_tab && !articleItem.has_dialogue">Транскриб</button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'mindmap')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'mindmap'" v-if="articleItem.mindmap">Mindmap</button>


            <button class="btn me-1 mb-1" :class="(viewMode == 'extra_data_type_'+t.id)?'btn-primary':'btn-outline-primary'" @click="viewMode = 'extra_data_type_'+t.id" v-for="t in articleItem.extra_data_types" :key="t.id" >{{t.caption}}</button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'files')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'files'" v-if="!articleItem.hide_file_tab && (articleItem.files_cnt || hasEditAccess)">Файлы <span class="badge bg-danger" v-if="articleItem.files_cnt">{{articleItem.files_cnt}}</span></button>

            <button class="btn me-1 mb-1" :class="(viewMode == 'collections')?'btn-primary':'btn-outline-primary'" @click="viewMode = 'collections'" v-if="hasEditAccess && !configData.hide_article_playlists && !articleItem.hide_article_playlists">Плейлисты и курсы <span class="badge bg-danger" v-if="articleItem.collections_cnt">{{articleItem.collections_cnt}}</span></button>


          </div>

          <div v-if="viewMode == 'podcast'">

            <h2>Слушайте подкаст</h2>
            <p class="alert alert-info">Друзья, этот подкаст был сгенерирован автоматически нейронной сетью. </p>
            <audio controls>
              <source :src="articleItem.podcast_audio_link" type="audio/mpeg">
            </audio>

          </div>

          <div v-for="t in articleItem.extra_data_types" :key="t.id">

               <ArticleDetailComponent :articleItem="articleItem" :typeId="t.id" v-if="viewMode == 'extra_data_type_'+t.id" />

          </div>
          
        <div v-if="viewMode == 'mindmap'">
          <div class="mindmap-container">
            <mindmap v-model="articleItem.mindmap" :zoom="true" :download-btn="true"></mindmap>
          </div>
          
        </div>

         <div v-if="viewMode == 'transcribe'">

            <h2>Транскрибация</h2>
            <div v-for="(fr, fr_index) in articleItem.text_fragments_transcriptions" :key="fr_index" class="d-flex align-items-start mb-1">
              <span class="badge bg-primary d-inline-block me-2 mt-1"  v-if="'start_time' in fr" >{{ formatTime(fr.start_time)}}</span>
              <span class="badge bg-primary d-inline-block me-2 mt-1"  v-if="'start' in fr">{{ formatTime(fr.start)}}</span>
              <div class="" v-html="fr.text"></div>
            </div>
            <button @click="copy(articleItem.title + '. ' + articleItem.text_raw)" class="mt-4 mb-2 btn btn-outline-success d-block d-md-inline-block" v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns && ( (!articleItem.protect_content && !articleItem.forbidden && !isPreview) || hasEditAccess)">
              Копировать текст
            </button>

            <button @click="generateGoogleDoc(articleItem)" :disabled="articleItem.generating_google_doc_url" class="mt-4 mb-2 btn btn-outline-primary d-block d-md-inline-block" v-if="!articleItem.google_doc_url">
              Сохранить Google Docs
            </button>

            <a :href="articleItem.google_doc_url" class="btn btn-primary mt-4 mb-2 d-block d-md-inline-block" v-if="articleItem.google_doc_url" target="_blank">
              Открыть в Google Docs
            </a>

            
          </div>


         <div v-if="viewMode == 'dialogue'">

            <h2>Диалог</h2>

            <ArticleDialogueComponent :materialHash="articleItem.hash"/>
            

            
          </div>

          <div v-if="viewMode == 'full'">            
            <div v-if="!editingMode">
              <div class="mindmap-container mb-4" v-if="articleItem.mindmap">
                <mindmap v-model="articleItem.mindmap" :zoom="true" :download-btn="true"></mindmap>
              </div>

              <div class="alert alert-info mb-4 mt-4" v-if="articleItem.chatbot_name == 'vid2text' && articleItem.text_fragments_transcriptions && !channelItem.disable_transcrib_tab && !articleItem.has_dialogue">
                <div>Нужен транскриб к этому материалу? </div>
                <button class="btn btn-outline-primary btn-sm mt-2" @click="viewMode = 'transcribe'">Смотреть транскриб</button>
              </div>

              <ArticleNextLessonComponent :articleItem = "articleItem" v-if="!articleItem.forbidden && !isPreview"/>


              <div v-if="(articleItem.chatbot_name == 'vid2text' || articleItem.chatbot_name == 'meetscribe')  && ( (!articleItem.protect_content && !articleItem.forbidden) || hasEditAccess)" class="mb-2">
                <button @click="copy(articleItem.title + '. ' + articleItem.text_raw)" class="btn btn-outline-success me-2 mb-2 d-block d-md-inline-block" v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns">
                  Копировать текст
                </button>


                <button @click="generateGoogleDoc(articleItem)" :disabled="articleItem.generating_google_doc_url" class="btn btn-outline-primary mb-2 d-block d-md-inline-block" v-if="!articleItem.google_doc_url">
                  Сохранить Google Docs
                </button>

                <a :href="articleItem.google_doc_url" class="btn btn-primary mb-2 d-block d-md-inline-block" v-if="articleItem.google_doc_url" target="_blank">
                  Открыть в Google Docs
                </a> 
              </div>

              <div v-html="articleItem.content" v-if="articleItem.content"></div>      
              <div v-if="!articleItem.content" class="text text-warning mb-4">Нет контента в статье</div>      
              
              <ArticleNextLessonComponent :articleItem = "articleItem" v-if="!articleItem.forbidden && !isPreview"/>

            </div>
            

            <div v-if="(editingMode)">
              <input v-model="articleItem.title" class="form-control mb-3">

              <div class="form-group mb-2">
                  <label for="description">Короткое описание (если нужно)</label>
                  <textarea id = "description" v-model="articleItem.description" class="form-control mb-3"></textarea>
              </div>  
              

              <div  class="editor-container">
              <QuillEditor
                theme="snow"
                v-model:content="articleItem.content"
                contentType="html"
                :toolbar="[
                  { 'header': [2, 3, 4, false] },
                  'bold',
                  'italic',
                  'underline',
                  'blockquote',
                  { list: 'ordered' },
                  { list: 'bullet' },
                  'link',
                  'clean',
                  'html'
                ]"
              />
              </div>
            </div>

              <div v-if="(articleItem.chatbot_name == 'vid2text' || articleItem.chatbot_name == 'meetscribe')  && ( (!articleItem.protect_content && !articleItem.forbidden) || hasEditAccess)">
                <button @click="copy(articleItem.title + '. ' + articleItem.text_raw)" class="btn btn-outline-success mb-2 d-block d-md-inline-block" v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns">
                  Копировать текст
                </button>

                <button @click="generateGoogleDoc(articleItem)" :disabled="articleItem.generating_google_doc_url" class="btn btn-outline-primary mb-2 d-block d-md-inline-block" v-if="!articleItem.google_doc_url">
                  Сохранить Google Docs
                </button>

                <a :href="articleItem.google_doc_url" class="btn btn-primary mb-2 d-block d-md-inline-block" v-if="articleItem.google_doc_url" target="_blank">
                  Открыть в Google Docs
                </a>
              </div>

            <div class="d-flex mt-3 justify-content-between align-items-center" v-if="!articleItem.forbidden && !articleItem.disable_manage_buttons ">
              <div class="d-flex ">

                <button @click="toggleEditingMode" class="btn btn-outline-primary" v-if="hasEditAccess">
                  {{ editingMode ? 'Сохранить и закрыть' : 'Редактировать статью' }}
                </button>
<!--                 <a :href="articleItem.telegram_edit_link" target="_blank" class="ms-1 btn btn-primary" v-if="hasEditAccess && articleItem.telegram_edit_link">
                  Управлять в Telegram
                </a> -->
              </div>

              

            </div>


            
          </div>
          <div v-if="viewMode == 'summary'">
            <div v-if="!editingModeSummary">
                <ArticleNextLessonComponent :articleItem = "articleItem" v-if="!articleItem.forbidden"/>

                <div v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns && ( (!articleItem.protect_content && !articleItem.forbidden && !isPreview) || hasEditAccess)" class="mb-2">
                  <button @click="copy(articleItem.title + '. ' + articleItem.text_summary, true)" class="btn btn-outline-success me-2 mb-2 d-block d-md-inline-block">
                    Копировать текст
                  </button>

                  <button @click="generateGoogleDoc(articleItem)" :disabled="articleItem.generating_google_doc_url" class="btn btn-outline-primary mb-2 d-block d-md-inline-block" v-if="!articleItem.google_doc_url">
                    Сохранить Google Docs
                  </button>

                  <a :href="articleItem.google_doc_url" class="btn btn-primary mb-2 d-block d-md-inline-block" v-if="articleItem.google_doc_url" target="_blank">
                    Открыть в Google Docs
                  </a>


<!--                   <button @click="copy('<h1>'+articleItem.title+'</h1>' + articleItem.text_summary)" class="btn btn-outline-success">
                    Копировать HTML
                  </button>
 -->

                </div>


                <div v-html="articleItem.text_summary"></div>

                <ArticleNextLessonComponent :articleItem = "articleItem" v-if="!articleItem.forbidden"/>

            </div>
            <div v-if="(editingModeSummary)">
              <input v-model="articleItem.title" class="form-control mb-3">

              <div  class="editor-container" >
              <QuillEditor
                theme="snow"
                v-model:content="articleItem.text_summary"
                contentType="html"
                :toolbar="[
                  { 'header': [2, 3, 4, false] },
                  'bold',
                  'italic',
                  'underline',
                  'blockquote',
                  { list: 'ordered' },
                  { list: 'bullet' },
                   'link',
                  'clean',
                  'html'
                ]"
              />
              </div>
            </div>
            
            <div class="d-flex justify-content-between align-items-center" v-if="!articleItem.forbidden && !isPreview">
              <button @click="toggleEditingModeSummary" class="btn btn-outline-primary" v-if="hasEditAccess">
                {{ editingModeSummary ? 'Сохранить и закрыть' : 'Редактировать конспект' }}
              </button>

<!--               <button @click="copy('<h1>'+articleItem.title+'</h1>' + articleItem.text_summary)" class="btn btn-outline-success" v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns && ( (!articleItem.protect_content && !articleItem.forbidden && !isPreview) || hasEditAccess)">
                Копировать HTML
              </button> -->

            </div>   

          </div>

          <div v-if="viewMode == 'checklist'" class="checklist">
            <div v-if="!editingModeChecklist">
              
              <div v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns && ( (!articleItem.protect_content && !articleItem.forbidden && !isPreview) || hasEditAccess)" class="mb-2">
                <button @click="copy(articleItem.title + '. ' + articleItem.text_checklist, true)" class="btn btn-outline-success me-2 d-block d-md-inline-block">
                  Копировать текст
                </button>

                <button @click="copy('<h1>'+articleItem.title+'</h1>' + articleItem.text_checklist)" class="btn btn-outline-success mb-2 d-block d-md-inline-block">
                  Копировать HTML
                </button>
              </div>

                <div v-html="articleItem.text_checklist"></div>
            </div>
            <div v-if="(editingModeChecklist)">
              <div  class="editor-container" >
              <QuillEditor
                theme="snow"
                v-model:content="articleItem.text_checklist"
                contentType="html"
                :toolbar="[
                  { 'header': [2, 3, 4, false] },
                  'bold',
                  'italic',
                  'underline',
                  'blockquote',
                  { list: 'ordered' },
                  { list: 'bullet' },
                   'link',
                  'clean',
                  'html'
                ]"
              />
              </div>
            </div>
            
            <div class="d-flex justify-content-between align-items-center" v-if="!articleItem.forbidden && !isPreview">
  <!--             <button @click="toggleEditingModeChecklist" class="btn btn-primary" v-if="hasEditAccess">
                {{ editingModeChecklist ? 'Сохранить и закрыть' : 'Редактировать чек-лист' }}
              </button> -->
              <button @click="copy('<h1>'+articleItem.title+'</h1>' + articleItem.text_checklist)" class="btn btn-outline-success" v-if="articleItem.chatbot_name == 'vid2text' && !configData.hide_copy_btns && ( (!articleItem.protect_content && !articleItem.forbidden && !isPreview) || hasEditAccess)">
                Копировать HTML
              </button>
            </div>   

          </div>

          <div v-if="viewMode == 'test'">
              <div v-if="articleItem.hasTest" >
                <a name="test"></a>
                <TestComponent/>
              </div>

          </div>

          <div v-if="viewMode == 'timecodes'">
            <TimecodesComponent  v-if="articleItem.hasTimecodes" />
          </div>

          <div v-if="viewMode == 'files'">
            <FilesComponent :materialId="articleItem.id"/>
          </div>

          <div v-if="viewMode == 'collections'">
            <MaterialCollectionsFormComponent :materialId="articleItem.id" v-if="hasEditAccess"/>
          </div>

          <div v-if="viewMode == 'timecodewords'">
            <TimecodeWordsComponent :materialId="articleItem.id" v-if="articleItem.status == 'processed'"/>
          </div>

          <div v-if="(!isPreview || hasEditAccess) && viewMode !== 'timecodewords' && !configData.hide_vid2text_integration_info">
            <form  v-if="user && !user.is_vid2text_user" class="mt-3">
              <div class="alert alert-info">
                <div><b>Автоматическое создание контента</b></div>
                <p>Хотите автоматически извлечь текст из видео и использовать его в качестве конспекта к уроку?
                Для этого перейдите в чат-бота <a href="https://t.me/vid2text_bot" target="_blank">@vid2text_bot</a>, нажми кнопку «Начать/Старт». После этого вернитесь назад и обновите эту страницу.</p>
                <div><a href="https://t.me/vid2text_bot?start=ref_bystrokurs_integration" target="_blank" class="btn btn-outline-primary">Настроить интеграцию с Vid2Text</a></div>

              </div>
            </form>
            <form  v-if="user && !isPreview && user.is_vid2text_user" class="mt-3">
              <div class="alert alert-info">
                <div><b>Автоматическое создание контента</b></div>
                <p>У вас настроена интеграция с чат-ботом Vid2Text. Вы можете в автоматическом режиме создать конспект, тест и другие доп. материалы к этому уроку на основе прикрепленного видео или аудио. Откройте этот материал в чат-боте Vid2Text, чтобы преобразовать видео в текст.</p>
                <div><a :href="articleItem.telegram_edit_link" target="_blank" class="btn btn-outline-primary">Открыть в Vid2Text</a></div>
              </div>
            </form>

            <form  v-if="user && isPreview && user.is_vid2text_user" class="mt-3">
              <div class="alert alert-info">
                <div><b>Автоматическое создание контента</b></div>
                <p>У вас настроена интеграция с чат-ботом Vid2Text. Вы можете в автоматическом режиме создать конспект, тест и другие доп. материалы к этому уроку на основе прикрепленного видео или аудио. Хотите перейти к созданию доп. контента на основе медиа-файла в этом материале?</p>
                <div><a :href="articleItem.telegram_edit_link_reset" target="_blank" class="btn btn-outline-primary">Создать статью по видео/аудио</a></div>
              </div>
            </form>
          </div>


          <div class="alert alert-info mt-3" v-if="!hasEditAccess && !articleItem.forbidden && channelItem.id && !channelItem.disable_request_editor_access_buttons && !isPreview">
            <div>Если вы являетесь автором этого материала и хотите получить доступ к редактированию этой страницы - запросите права редактора.</div>

            <div class="row align-items-center">
              <div class="col col-auto ">
                <button @click="requestArticleAccess" class="btn btn-primary mt-2 me-3" :disabled="!isAuthenticated"><i class="bi bi-pencil"></i>
                   Запросить права редактора
                </button>
              </div>
              <div class="col col-auto align-items-center" v-if="!isAuthenticated">
                ⚠️ Требуется войти через Telegram
              </div>
            </div>
          </div>

          <a name="auth"></a>
           <!-- && !articleItem.forbidden -->

          <div class="card mt-3" v-if="!isPreview && !isAuthenticated && configData.service_name && !configData.disable_telegram_auth">
            <h4 class="card-header"><i class="bi bi-person-fill"></i> Войдите в личный кабинет</h4>
            <div class="card-body">

              <p v-if="articleItem.group_alias !== 'EduGrowth'">Чтобы получить доступ к этому материалу или пройти тест - войдите через Telegram.</p>
              <p v-if="articleItem.group_alias == 'EduGrowth'">Чтобы просмотреть материал полностью - <a href="#header">войдите через Telegram</a>.</p>
              <!-- <telegram-auth></telegram-auth> -->
            </div>

          </div>
        </div>

<!--         <div class="card mt-3" v-if="!isPreview && isAuthenticated && !configData.disable_telegram_auth">
          <h4 class="card-header"><i class="bi bi-person-fill"></i> Вы вошли </h4>
          <div class="card-body">
            <telegram-auth></telegram-auth>
          </div>
        </div> -->


      </div>
         <!-- <ArticleComponent />
         -->
      </div>

    </div>
    <div class="col-lg-4" v-if="!isPreview">
      <SidebarComponent/>
    </div>
  </div>      
  <OtherArticlesComponent displayFormat="horizontal" v-if="!isMobile && articleItem.id && !articleItem.collection_id && !articleItem.disable_other_articles && !isPreview"/>
</template>

<script>
import { useToast } from "vue-toastification";
import { mapGetters, mapActions, useStore } from "vuex";
import { computed, watchEffect, getCurrentInstance } from 'vue';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useClipboard from 'vue-clipboard3';
import mindmap from 'ch-vue3-mindmap';
import TestComponent from '../components/TestComponent.vue';
import TimecodesComponent from '../components/TimecodesComponent.vue';
import OtherArticlesComponent from '../components/OtherArticlesComponent.vue';
import SidebarComponent from '../components/SidebarComponent.vue';
import ArticleDetailComponent from '../components/ArticleDetailComponent.vue';
import ArticleDialogueComponent from '../components/ArticleDialogueComponent.vue';
import FilesComponent from '../components/FilesComponent.vue';
import TimecodeWordsComponent from '../components/TimecodeWordsComponent.vue';
import MaterialCollectionsFormComponent from '../components/MaterialCollectionsFormComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import ArticleNextLessonComponent from '@/components/ArticleNextLessonComponent.vue';
import AddMediaModalComponent from '@/components/AddMediaModalComponent.vue';

export default {
  components: {
    QuillEditor,
    TestComponent,
    TimecodesComponent,
    TimecodeWordsComponent,
    OtherArticlesComponent,
    SidebarComponent,
    ArticleDetailComponent,
    ArticleDialogueComponent,
    FilesComponent,
    MaterialCollectionsFormComponent,
    SpinnerComponent,
    ArticleNextLessonComponent,
    AddMediaModalComponent,
    mindmap
  },

  data() {
    return {
      viewMode: '',
      showAddMediaModal: false,
      mediaUploading: false,
      showFullText: false,
      editingMode: false,
      editingModeSummary: false,
      editingModeChecklist: false
    };
  },

  computed: {
    ...mapGetters('app', ['isMobile', 'configData', 'isPreview']),
    ...mapGetters("article", ["getArticleItem"]),
    ...mapGetters("channel", ["getChannelItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
    channelItem: {
      get() {
        return this.getChannelItem || {};
      },
      set(value) {
        this.updateChannelItem(value);
      },
    },
  },

  watch: {
    isPreview(newVal) {
      this.updateViewMode(newVal);
    }
  },

  created() {
    const hash = this.$route.params.id;
    this.updateHash(hash);
    this.timer = setTimeout(this.fetchArticle, 1000);
  },

  methods: {
    ...mapActions('hash', ['updateHash']),
    ...mapActions("article", ["fetchArticle", "saveArticle", "generateGoogleDoc"]),
    ...mapActions("channel", ["fetchChannel", "saveChannel"]),
    ...mapActions('material', ['saveMaterialMedia']),
    ...mapActions('app', ['setPreview']),
    
    toggleEditingMode() {
      if (this.editingMode) {
        this.saveArticle();
      }
      this.editingMode = !this.editingMode;
    },
    toggleEditingModeSummary() {
      if (this.editingModeSummary) {
        this.saveArticle();
      }
      this.editingModeSummary = !this.editingModeSummary;
    },
    toggleEditingModeChecklist() {
      if (this.editingModeChecklist) {
        this.saveArticle();
      }
      this.editingModeChecklist = !this.editingModeChecklist;
    },
    formatTime(seconds) {
      const date = new Date(null);
      date.setSeconds(seconds);
      return date.toISOString().substr(11, 8);
    },
    saveAddMedia() {
      this.showAddMediaModal = false; 
      this.saveArticle();
    },
    cancelAddMediaModal() {
      this.showAddMediaModal = false; 
    },
    handleShowAddMediaModal() {
      this.showAddMediaModal = true;
    },
    handleAddMediaSave() {
      this.fetchArticle();
    },
    async handleMediaUpload(event) {
      const files = event.target.files;
      for (let file of files) {
        if (!file) return;

        const formData = new FormData();
        formData.append('mediaFile', file);

        this.mediaUploading = true;

        try {
          const response = await this.saveMaterialMedia({
            materialId: this.articleItem.id,
            mediaForm: formData
          });

          this.articleItem.telegram_item_link = response.telegram_item_link;
          this.mediaUploading = false;
          this.cancelAddMediaModal();
        } catch (error) {
          this.mediaUploading = false;
          this.cancelAddMediaModal();
          console.error('Error uploading file:', error);
        }
      }
    },


    fetchArticle() {
      const urlParams = new URLSearchParams(window.location.search);
      const resultsHash = urlParams.get('results_hash');
      this.$store.dispatch("article/fetchArticle", this.$store.$axiosInstance)
        .then(() => {
          if (resultsHash) {
            this.viewMode = 'test';
          } else {
            if (this.configData.service_name == 'narezkavideo'){
              this.viewMode = 'timecodewords';
              this.setPreview('timecodewords');
            } else {
              this.viewMode = "full";
              if (this.getArticleItem.forbidden){
                this.viewMode = "full";
              } else {
                if (this.isPreview && this.isPreview !== 'article'){
                  this.viewMode = this.isPreview;
                } else {
                  if (this.getArticleItem.text_summary){
                    this.viewMode = "summary";
                    if (this.isPreview == 'article' && this.getArticleItem.content){
                      this.viewMode = "full"; 
                    }
                  } else if (this.getArticleItem.content){
                    this.viewMode = "full";
                  } else if (this.getArticleItem.hasTest){
                    if (this.isPreview == 'article'){
                      this.viewMode = "full";
                    } else {
                      this.viewMode = "test";
                    }
                  } else if (this.getArticleItem.hasTimecodes){
                    this.viewMode = "timecodes";
                  }
                }
              }
            }
            document.title = this.getArticleItem.title + ' – ' + this.configData.site_name;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveArticle() {
      this.$store.dispatch("article/saveArticle", this.$store.$axiosInstance)
        .then(() => {
          const toast = useToast();
          toast.success("Статья успешно обновлена", {
            timeout: 2000,
          });
          this.fetchArticle();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateGoogleDoc() {
      this.articleItem.generating_google_doc_url = true;
      this.$store.dispatch("article/generateGoogleDoc", this.$store.$axiosInstance)
        .then((response) => {
          this.articleItem.generating_google_doc_url = false;
          const toast = useToast();
          toast.success("Статья успешно добавлена в Google Docs", {
            timeout: 2000,
          });

          if (response && response.url) {
            window.open(response.url, '_blank');
          }

          this.fetchArticle();
        })
        .catch((error) => {
          this.articleItem.generating_google_doc_url = false;
          console.log(error);
        });
    },

    requestArticleAccess() {
      this.$store.dispatch("article/requestArticleAccess", this.$store.$axiosInstance)
        .then(() => {
          const toast = useToast();
          toast.success("Запрос на получение доступа к редактированию статьи отправлен. Напишем вам в Telegram.", {
            timeout: 2000,
          });
          this.fetchArticle();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateViewMode(newVal) {
      this.viewMode = newVal;
    }
  },

  setup() {
    const store = useStore();
    const user = computed(() => store.getters['user/getUser']);
    const hasEditAccess = computed(() => store.getters['user/hasEditAccess']);
    const isAuthenticated = computed(() => store.getters['user/isAuthenticated']);
    const instance = getCurrentInstance();

    const { toClipboard } = useClipboard();

    const stripHTML = (html) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      doc.querySelectorAll('li').forEach(el => {
        el.textContent = '– ' + el.textContent;
        el.insertAdjacentHTML('afterend', '\n');
      });
      doc.querySelectorAll('h1, h2, h3, h4, h5, p, div').forEach(el => {
        el.insertAdjacentHTML('afterend', '\n\n');
      });
      doc.querySelectorAll('ul').forEach(el => {
        el.insertAdjacentHTML('afterend', '\n\n');
      });
      let text = doc.body.textContent || "";
      text = text.replace(/\n{3,}/g, '\n\n');
      return text;
    };

    const copy = async (text, clean_html) => {
      try {
        if (clean_html) {
          text = stripHTML(text);
        }
        await toClipboard(text);
        const toast = useToast();
        toast.success("Скопировано в буфер обмена", {
          timeout: 2000
        });
      } catch (e) {
        console.error(e);
      }
    }

    watchEffect(() => {
      if (isAuthenticated.value && instance.proxy.fetchArticle) {
        instance.proxy.fetchArticle();
      }
    });

    return {
      hasEditAccess,
      isAuthenticated,
      user,
      copy
    }
  }
};
</script>


<style>
.ql-snow .ql-editor blockquote {
  padding-top: 10px;
  padding-bottom: 10px;
}

.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6{
  margin-bottom: 10px;
}

.editor-container {
  height: 400px; /* Set the desired height for the editor */
  padding-bottom: 60px;
}

iframe.video{
  width: 100%;
  max-width: initial;
  height: auto;
  min-height: 400px;
  border-radius: 10px;
}

.screenshots{
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  margin: 10px 0;
}

.screenshots img{
  width: 250px;
  height: auto;
  margin-right: 10px;
  border-radius: 10px;
}

.container.protect_content{
    -webkit-user-select: none;  
    -moz-user-select: none;  
    -ms-user-select: none;  
    -o-user-select: none;  
    user-select: none;
}

.summary-block h2{
  font-size: 22px;
}

h3{
  color: #5e4da7 !important;
}
.summary-block h3{
  font-size: 20px;
  
}

ul li::marker {
    color: #a28efb;
}

.tasks{

  /*  
  padding: 20px;
  margin: 20px 0;
  background: #EFEFEF;
  border-radius: 8px;
  */
}

.tasks .inner{
  padding-left: 14px;
  margin-bottom: 12px;
}

@media (max-width: 768px) {
  .tasks .inner {
    padding-left: 0;
  }
}

html[data-bs-theme="dark"] .tasks{
  background-color: #171819 !important;
}

.hl-header{
  background: #b2a2f9;
  display: inline-block;
  padding: 6px 15px;
  border-radius: 3px;
  margin-top: 10px;
  color: #FFF;
  font-size: 20px;
  font-weight: normal; 
}

.header-block{
  padding: 20px;
  background: #0d6efd;
  color: #FFF;
  border-radius: 6px;  
}

img.cover{

}

pre {
  margin: 10px 0 !important;
  white-space: pre-wrap;
}

.btn-tg-video{
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.btn-gradient {
/*  overflow: hidden;
  background: linear-gradient(45deg, #9045ce, #b855bb, #ffaed8, #8a43d1);
  background-size: 200% 200%;
  animation: gradientAnimation 2s ease-in-out infinite;
  border: 0;*/

  border-radius: 10px;
  border: 3px solid #FFF !important;
  overflow: hidden;
  background: linear-gradient(45deg, #f70c4d, #ff7da9, #ffaed8, #ff2626);
  background-size: 200% 200%;
  animation: gradientAnimation 2s ease-in-out infinite;  
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.checklist {
  counter-reset: itemNumber;
} 

.checklist .description{
  font-size: 1.2em;
  margin-bottom: 20px;
}

.checklist .caption{
  font-weight: 600;
  padding-top: 6px;
}

.checklist .cl-item{
  margin-bottom: 10px;
  background: #f1ecff;
  border-radius: 6px;
  display: flex;
  align-items: stretch;
}

html[data-bs-theme="dark"] .checklist .cl-item{
  background-color: #303943 !important;
}

html[data-bs-theme="dark"] .bg-light{
  background-color: transparent !important;
}

.checklist .cl-item>div{
  padding-right: 16px;
}

.cl-item::before {
    counter-increment: itemNumber;
    content: counter(itemNumber);
    min-width: 50px;
    text-align: right;
    margin-right: 10px;
    font-size: 1.4em;
    font-weight: 600;
    color: #ffffff;
    padding: 10px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #aa50c2;
}

.report-score{
  font-size: 1.2em;
  white-space: nowrap;
  margin-left: 10px;
  margin-top: 10px;
}

.breadcrumbs{
  padding: 4px;
  border-bottom: 1px solid #EFEFEF;
  margin-bottom: 5px;
  padding-bottom: 8px;  
}

.checklist .descr{
  padding-bottom: 6px;
}

.mindmap-container{
  height: 600px;
  border-radius: 10px;
  overflow: hidden;  
}

@media (max-width: 767px) {
  .mindmap-container {
    height: 300px;
  }
}

.insights {
  margin-top: 16px;
  margin-bottom: 8px;
}
</style>
