<template>
  <div>
    <!-- Настройка ориентации видео с пиктограммами -->
    <div class="mt-2">
      <label :for="'orientationSelect' + videoId">Ориентация видео</label>
      <div class="d-flex justify-content-between" :id="'orientationSelect' + videoId">
        <div 
          v-for="orientation in orientations" 
          :key="orientation.value" 
          :class="['orientation-icon', { selected: orientation.value === localSelectedOrientation }]" 
          @click="selectOrientation(orientation.value)">
          <i :class="orientation.icon"></i>
        </div>
      </div>
    </div>

    <!-- Блок настроек субтитров, видимый только если субтитры не скрыты -->
    <div v-if="!localHideSubtitles || localHideSubtitles == 'false'">
      <div class="mt-2">
        <label :for="'colorSelect' + videoId">Цвет субтитров:</label>
        <div :id="'colorSelect' + videoId" class="d-flex flex-column">
          <!-- Верхний ряд: цветные квадраты с белой буквой "A" -->
          <div class="d-flex">
            <div 
              v-for="color in colors" 
              :key="color.value" 
              :class="['color-box', { selected: color.alias === localSelectedColor }]"
              :style="{ backgroundColor: color.value }" 
              @click="selectColor(color.alias)">
              <span class="letter white-letter">A</span>
            </div>
          </div>
          <!-- Нижний ряд: белые квадраты с цветной буквой "A" -->
          <div class="d-flex mt-2">
            <div 
              v-for="color in colors" 
              :key="color.value + '_letter'" 
              :class="['color-box', { selected: color.alias + '_letter' === localSelectedColor }]"
              :style="{ backgroundColor: '#fff' }" 
              @click="selectColor(color.alias + '_letter')">
              <span :style="{ color: color.value }" class="letter">A</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <label :for="'alignmentSelect' + videoId">Выравнивание:</label>
        <select :id="'alignmentSelect' + videoId" class="form-select" v-model="localSelectedAlignment">
          <option value="top">По верхнему краю</option>
          <option value="center">По центру</option>
          <option value="bottom">По нижнему краю</option>
        </select>
      </div>

      <div class="mt-2">
        <label :for="'wordsPerLineSelect' + videoId">Слов в строке:</label>
        <select :id="'wordsPerLineSelect' + videoId" class="form-select" v-model.number="localWordsPerLine">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </div>

      <div class="mt-2">
        <label :for="'fontSelect' + videoId">Выберите шрифт:</label>
        <select :id="'fontSelect' + videoId" class="form-select" v-model="localSelectedFont">
          <option v-for="font in fonts" :key="font.value" :value="font.value">
            {{ font.alias }}
          </option>
        </select>
      </div>

      <!-- Новая настройка: размер шрифта -->
      <div class="mt-2">
        <label :for="'fontSizeSelect' + videoId">Размер шрифта:</label>
        <select :id="'fontSizeSelect' + videoId" class="form-select" v-model="localSelectedFontSize">
          <option value="small">Мельче</option>
          <option value="normal">Обычный</option>
          <option value="large">Крупнее</option>
        </select>
      </div>

      <!-- Новая настройка: обводка -->
      <div class="mt-2">
        <label :for="'outlineSelect' + videoId">Обводка:</label>
        <select :id="'outlineSelect' + videoId" class="form-select" v-model="localSelectedOutline">
          <option value="none">Без обводки</option>
          <option value="light">Светлая</option>
          <option value="dark">Темная</option>
        </select>
      </div>

      <!-- Новая настройка: использовать заглавные буквы -->
      <div class="mt-4 d-flex align-items-center">
        <label class="switch">
          <input type="checkbox" :id="'uppercase' + videoId" v-model="localUppercase">
          <span class="slider round"></span>
        </label>
        <label :for="'uppercase' + videoId" class="ms-2">Использовать заглавные буквы</label>
      </div>      
    </div>

    <!-- Тогглер "Скрыть субтитры" слева от подписи -->
    <div class="mt-4 d-flex align-items-center">
      <label class="switch">
        <input type="checkbox" :id="'hideSubtitles' + videoId" v-model="localHideSubtitles">
        <span class="slider round"></span>
      </label>
      <label :for="'hideSubtitles' + videoId" class="ms-2">Скрыть субтитры</label>
    </div>

    <!-- Тогглер "Отслеживать положение лица" слева от подписи -->
    <div class="mt-4 mb-4 d-flex align-items-center">
      <label class="switch">
        <input type="checkbox" :id="'trackFace' + videoId" v-model="localTrackFace">
        <span class="slider round"></span>
      </label>
      <label :for="'trackFace' + videoId" class="ms-2">Следить за лицом</label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Array,
      required: true
    },
    videoId: {
      type: Number,
      default: 0
    },    
    
    fonts: {
      type: Array,
      required: true
    },
    selectedColor: {
      type: String,
      default: ''
    },
    selectedAlignment: {
      type: String,
      default: ''
    },
    wordsPerLine: {
      type: Number,
      default: 1
    },
    selectedFont: {
      type: String,
      default: ''
    },
    selectedFontSize: {
      type: String,
      default: 'normal'
    },
    selectedOrientation: {
      type: String,
      default: 'horizontal'
    },
    hideSubtitles: {
      type: String,
      default: 'false'
    },
    trackFace: {
      type: String,
      default: 'false'
    },
    uppercase: {
      type: String,
      default: 'false'
    },
    selectedOutline: {
      type: String,
      default: 'none'
    }
  },
  data() {
    return {
      localSelectedColor: this.selectedColor,
      localSelectedAlignment: this.selectedAlignment,
      localWordsPerLine: this.wordsPerLine,
      localSelectedFont: this.selectedFont,
      localSelectedFontSize: this.selectedFontSize,
      localSelectedOrientation: this.selectedOrientation,
      localHideSubtitles: this.hideSubtitles,
      localTrackFace: this.trackFace,
      localUppercase: this.uppercase,
      localSelectedOutline: this.selectedOutline,
      orientations: [
        { value: 'vertical', icon: 'bi bi-phone' },
        { value: 'square', icon: 'bi bi-square' },
        { value: 'horizontal', icon: 'bi bi-tv' }
      ]
    };
  },
  watch: {
    selectedColor(newVal) {
      this.localSelectedColor = newVal;
    },
    selectedAlignment(newVal) {
      this.localSelectedAlignment = newVal;
    },
    wordsPerLine(newVal) {
      this.localWordsPerLine = newVal;
    },
    selectedFont(newVal) {
      this.localSelectedFont = newVal;
    },
    selectedFontSize(newVal) {
      this.localSelectedFontSize = newVal;
    },
    selectedOrientation(newVal) {
      this.localSelectedOrientation = newVal;
    },
    hideSubtitles(newVal) {
      this.localHideSubtitles = newVal;
    },
    trackFace(newVal) {
      this.localTrackFace = newVal;
    },
    uppercase(newVal) {
      this.localUppercase = newVal;
    },
    selectedOutline(newVal) {
      this.localSelectedOutline = newVal;
    },
    localSelectedColor(newVal) {
      this.$emit('update:selectedColor', newVal);
      this.$emit('update');
    },
    localSelectedAlignment(newVal) {
      this.$emit('update:selectedAlignment', newVal);
      this.$emit('update');
    },
    localWordsPerLine(newVal) {
      this.$emit('update:wordsPerLine', parseInt(newVal));
      this.$emit('update');
    },
    localSelectedFont(newVal) {
      this.$emit('update:selectedFont', newVal);
      this.$emit('update');
    },
    localSelectedFontSize(newVal) {
      this.$emit('update:selectedFontSize', newVal);
      this.$emit('update');
    },
    localSelectedOrientation(newVal) {
      this.$emit('update:selectedOrientation', newVal);
      this.$emit('update');
    },
    localHideSubtitles(newVal) {
      this.$emit('update:hideSubtitles', newVal);
      this.$emit('update');
    },
    localTrackFace(newVal) {
      this.$emit('update:trackFace', newVal);
      this.$emit('update');
    },
    localUppercase(newVal) {
      this.$emit('update:uppercase', newVal);
      this.$emit('update');
    },
    localSelectedOutline(newVal) {
      this.$emit('update:selectedOutline', newVal);
      this.$emit('update');
    }
  },
  methods: {
    selectColor(color) {
      this.localSelectedColor = color;
    },
    selectOrientation(orientation) {
      this.localSelectedOrientation = orientation;
    }
  }
}
</script>

<style scoped>
.color-box {
  width: 24px;
  height: 24px;
  margin-right: 8px;
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-box.selected {
  border: 2px solid #fc5656;
}

.letter {
  font-size: 16px;
  font-weight: bold;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
}

.white-letter {
  color: #fff;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 1);
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.orientation-icon {
  cursor: pointer;
  font-size: 24px;
  margin: 0 10px;
  color: #ccc;
  transition: color 0.3s;
}

.orientation-icon.selected {
  color: #2196F3;
}
</style>
