<template>

<div class="container">
  <header class="" v-if="showHeader && configData.site_caption">
    <HeaderComponent />
    <!-- Код для блока заголовка -->
  </header>
<header class="" v-if="!showHeader && configData.site_caption">
    <HeaderChatbotsComponent />
  </header>


    <main>
      <router-view></router-view>
    </main>


  <FooterComponent v-if="showFooter"/>

</div>

</template>



<script>
import HeaderComponent from './components/HeaderComponent.vue';
import HeaderChatbotsComponent from './components/HeaderChatbotsComponent.vue';


import FooterComponent from './components/FooterComponent.vue';


import { mapActions, mapGetters, useStore} from 'vuex';
import { computed, onMounted } from 'vue';



export default {
  components: {
    HeaderComponent,
    HeaderChatbotsComponent,
    FooterComponent
  },
  watch: {
    $route: {
        immediate: true,
        // eslint-disable-next-line
        handler(to, from) { 
            document.title = to.meta.title || 'Default Title';
        }
    },
  },
  data() {
    return {
      videoTitle: '',
      textGenerated: '',
      showTest: true,
      showTimecodes: false,
      appMounted: false,
      showHeader: true,
      showFooter: true,
      previewMode: false

    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const hash = urlParams.get('hash');
    const preview = urlParams.get('preview'); // Добавлено получение параметра preview
    const chatbot_name = urlParams.get('chatbot_name'); // Добавлено получение параметра preview

    console.log(hash);
    console.log(preview);
    this.updateHash(hash); // Dispatch the updateHash action
    
    // Если параметр preview равен 'article', то скрыть header и footer
    if (preview) {
        this.showHeaderFooter(false);
        this.enablePreview(preview);
    }    
    if (chatbot_name){
      this.setChatbotName(chatbot_name);
    }
  },
  methods: {
    ...mapActions('hash', ['updateHash']), // Map the updateHash action
    ...mapActions('app', ['setPreview', 'setChatbotName']),
    
    enablePreview(type) {
      this.setPreview(type);
    },    
    showHeaderFooter(show) {
        this.showHeader = show;
        this.showFooter = show;
    },
  },
  computed: {
    ...mapGetters('app', ['isMobile', 'isPreview', 'chatbotName', 'configData']), 
    ...mapGetters('hash', ['getHash']), // Map the getHash getter
    ...mapGetters("article", ["getArticleItem"]),
    articleItem: {
      get() {
        return this.getArticleItem || {};
      },
      set(value) {
        this.updateArticleItem(value);
      },
    },
  },


  mounted() {
    this.appMounted = true
    // Вставка скрипта Telegram Web Apps при монтировании
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-web-app.js';
    script.async = true;

    script.onload = () => {
      // Ваш код после полной загрузки скрипта
      var WebApp = window.Telegram.WebApp;

      console.log(WebApp);
      if (typeof WebApp.initDataUnsafe.user !== 'undefined'){
        // авторизация пользователя
        
        var user = WebApp.initDataUnsafe.user;
        user.hash = WebApp.initDataUnsafe.hash;
        user.query_id = WebApp.initDataUnsafe.query_id;
        user.auth_date = WebApp.initDataUnsafe.auth_date;
        this.store.dispatch('user/loginUser', user); 
        console.log(WebApp.initDataUnsafe.user);
      }
      


      // // Обработка данных о пользователе при входе через Telegram
      // document.addEventListener('tg-external-auth', (event) => {
      //   const { user } = event.detail;
      //   // Обработайте данные о пользователе, например, отправьте на сервер
      //   console.log('Получены данные о пользователе:', user);
      // });
    };
    document.head.appendChild(script);




  },
  setup() {
    const store = useStore();

    onMounted(async () => {
      console.log('fetch config data');
      // Вызовите новое действие для загрузки конфигурационных данных
      await store.dispatch('app/fetchConfigData');

      const configData = store.state.app.configData;
      document.title = configData.site_caption;
    });

    const article = computed(() => store.getters['article/getArticleItem']);

    return {
      article,
      store
    };
  }
};
</script>

<style>
    body {
/*      font-family: 'Roboto', sans-serif;*/
/*      background: url(assets/bg-stars.svg) repeat-y;
      background-position: center 50%;
*/    
    }

    .header {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
    }

    .inner {
/*      max-width: 800px;*/
    }

    #app {
      min-height: 100vh;
    }
    .tg-link {
      flex-grow: 1;
    }

    .menu a {
      margin-left: 10px;
    }

    textarea {
      border: 1px solid #BFBFBF;
      border-radius: 10px;
    }

    .time {
      font-weight: 600;
    }

    .relative{
      position: relative;
    }

    blockquote {
      background: #f1ecff;
      /* border-radius: 8px; */
      margin-bottom: 10px;
      padding: 20px;
      border-left: 4px solid #cabeff;
    }

    .absolute-center-x {
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      left: 50%;
    }
    .text-success {
      color: #339933;
    }

    .fragment-imgs {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 10px;
      margin: 20px 0;
    }

    .fragment-imgs img {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-right: 5px;
    }

    h3 {
      color: #444;
    }

    html[data-bs-theme="dark"] blockquote {
      background-color: #344556;
    }

    html[data-bs-theme="dark"] h1, html[data-bs-theme="dark"] h2, html[data-bs-theme="dark"] h3, html[data-bs-theme="dark"] h4 {
      color: #FFF !important;
    }

    html[data-bs-theme="dark"] .shadow, html[data-bs-theme="dark"] .shadow-sm {
      box-shadow: none !important;
    }

    blockquote p {
      margin: 0;
    }

    .modal-xl{
      max-width: 90%;
    }

    .preview{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .preview body{
      min-width: 100%;
    }

    .xs{
      font-size: 0.5em;
    }
  
    .btn-outline-light-2{
      opacity:0.4
    }    

    
</style>
