<template>
  <div v-if="user_info" class="info-container mt-4">
    <div class="info-item" v-if="user_info.id">
      <h5>ID</h5>
      <p>{{ user_info.id }}</p>
      <p>{{ user_info.username }}</p>
    </div>

    <div class="info-item">
      <h5>Обработано</h5>
      <p>Всего: {{ user_info.materials.total }}</p>
      <p>Успешно: {{ user_info.materials.statuses.processed }}  ({{ processedPercentage }}%)</p>
    </div>

    <div class="info-item">
      <h5>Пополнения</h5>
      <p>Внесено: {{ user_info.topups.sum_original }} ₽</p>        
      <p v-if="user_info.topups.sum_final">С бонусами: {{ user_info.topups.sum_final }} ₽</p>
    </div>

    <div class="info-item">
      <h5>Списания</h5>
      <p>Всего: {{ user_info.withdrawals.sum_final }} ₽</p>
      <p v-if="user_info.withdrawals.sum_original">Sum Original: {{ user_info.withdrawals.sum_original }} ₽</p>
    </div>

    <div class="info-item">
      <h5>Баланс</h5>
      <p>{{ user_info.balance }} ₽</p>
      <button @click="openTransactionsModal" class="btn btn-primary">Просмотреть транзакции</button>
    </div>

        <!-- Модальное окно для пополнений и списаний -->
    <div class="modal" tabindex="-1" role="dialog" :class="{ 'd-block': showTransactionsModal }">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title">Транзакции пользователя</h2>
            <button type="button" class="btn btn-light" @click="closeTransactionsModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- Пополнения -->
            <h5>Пополнения</h5>
            <ul class="list-group">
              <li v-for="topup in transactions_list.topups" :key="topup.id" class="list-group-item d-flex justify-content-between align-items-center">
                <span> <span class="text text-muted small">{{topup.id}}</span> {{ topup.created_at }} — {{ topup.sum_original }} ₽ <span v-if="topup.refunded_amount !== '0'" class="text text-info small">возвращено: {{topup.refunded_amount}} {{topup.refunded_date}}</span>
                </span>
                <button @click="refundTopup(topup)" class="btn btn-warning btn-sm" v-if="topup.refunded_amount == '0' && topup.monetabot_acquiring_id !== '0'">Вернуть</button>
              </li>
            </ul>

            <!-- Списания -->
            <h5 class="mt-4">Списания</h5>
            <ul class="list-group">
              <li v-for="withdrawal in transactions_list.withdrawals" :key="withdrawal.id" class="list-group-item d-flex justify-content-between align-items-center">
                <span> 
                  <span class="text text-muted small">{{withdrawal.id}}</span> {{ withdrawal.created_at }} — {{ withdrawal.sum_final }} ₽
                </span>
                <button @click="deleteWithdrawal(withdrawal)" class="btn btn-danger btn-sm">Удалить</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    user_info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      transactions_list: [],
      showTransactionsModal: false // Контроль видимости модального окна
    };
  },
  computed: {
    ...mapGetters('transactions', ['getTransactions']),

    processedPercentage() {
      const total = this.user_info.materials.total;
      const processed = this.user_info.materials.statuses.processed;
      return total > 0 ? ((processed / total) * 100).toFixed(2) : 0;
    }
  },
  methods: {
    // Метод для загрузки транзакций
    loadTransactions() {
      return this.$store.dispatch('transactions/fetchTransactions', this.user_info.id) // Вызов Vuex action для загрузки транзакций
        .then((transactions) => {
          this.transactions_list = transactions;
        })
        .catch((error) => {
          console.error('Ошибка получения транзакций:', error); // Обработка ошибок
        });
    },

    // Открытие модального окна с транзакциями
    openTransactionsModal() {
      this.loadTransactions() // Загрузка транзакций при открытии модального окна
        .then(() => {
          this.showTransactionsModal = true; // Показать модальное окно после загрузки данных
        });
    },

    // Закрытие модального окна
    closeTransactionsModal() {
      this.showTransactionsModal = false;
    },

    // Метод для возврата пополнения
    refundTopup(topup) {
      this.$store.dispatch('transactions/refundTopup', { userId: this.user_info.id, topupId: topup.id }) // Вызов Vuex action для возврата
        .then(() => {
          console.log('Пополнение возвращено');
          this.loadTransactions(); // Обновление списка транзакций после возврата
        })
        .catch((error) => {
          console.error('Ошибка возврата пополнения:', error); // Обработка ошибок
        });
    },

    // Метод для удаления списания
    deleteWithdrawal(withdrawal) {
      this.$store.dispatch('transactions/deleteWithdrawal', { userId: this.user_info.id, withdrawalId: withdrawal.id }) // Вызов Vuex action для удаления
        .then(() => {
          console.log('Списание удалено');
          this.loadTransactions(); // Обновление списка транзакций после удаления списания
        })
        .catch((error) => {
          console.error('Ошибка удаления списания:', error); // Обработка ошибок
        });
    }
  }

};
</script>

<style scoped>
.info-container {
  /* Стили для контейнера */
}

.info-item {
  margin-bottom: 1rem;
}

/* Стили для модального окна */
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 800px;
}

.modal-content {
  padding: 20px;
}
</style>
